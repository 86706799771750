import { Box, CardContent, useTheme } from '@material-ui/core';
import React from 'react';
import useFormatter from '../../hooks/useFormatter';
import { GreyTypography } from '../core/Typography/CustomTypography';
import ColoredCard from './ColoredCard';

interface RecipeCardProps {
  title: string;
  recipeRates: {
    type: string;
    value: number;
  }[];
}

const RecipeCard: React.FC<RecipeCardProps> = ({ title, recipeRates }) => {
  const { currencyFormatter } = useFormatter();
  const theme = useTheme();
  return (
    <ColoredCard fullWidth backgroundColor={theme.palette.info.main} boxShadow={theme.shadows[3]}>
      <CardContent>
        <GreyTypography variant='h5' align='center'>
          <strong>{title}</strong>
        </GreyTypography>
        <Box marginY='5px'>
          <GreyTypography variant='subtitle1' align='center'>
            Valor de Venda:
          </GreyTypography>
        </Box>
        {recipeRates.map(({ type, value }) => (
          <Box key={type} justifyItems='center' paddingX='10%' gridTemplateColumns='1fr 1fr' marginY='10px' display='grid'>
            <GreyTypography display={'inline'}>{`${type}:`}</GreyTypography>
            <GreyTypography display={'inline'}>
              <strong>{currencyFormatter(value)}</strong>
            </GreyTypography>
          </Box>
        ))}
      </CardContent>
    </ColoredCard>
  );
};

export default RecipeCard;
