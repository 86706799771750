import { Box, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import imagemEmpresa from '../../assets/img/construction.svg';
import LoginContainer from '../../components/containers/LoginContainer/LoginContainer';
import SignUpEmpresaForm from '../../components/forms/SignUpForm/SignUpEmpresaForm';
import { User } from '../../model/User.entity';

const SignUpEmpresa = () => {
  const { location, push } = useHistory();
  const { state } = location as { state: User };

  console.log(state);

  return (
    <LoginContainer text='Agora vamos cadastrar sua empresa' img={imagemEmpresa}>
      <Box position='absolute' top='1%' left='0' color='common.black' fontSize='h2.fontSize'>
        <IconButton size='medium' color='inherit' onClick={() => push('/signup', state)}>
          <ArrowBack fontSize='large' color='inherit' />
        </IconButton>
      </Box>

      <SignUpEmpresaForm />
    </LoginContainer>
  );
};

export default SignUpEmpresa;
