import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';

interface ConfirmDialogProps {
  handleClose: React.MouseEventHandler<HTMLButtonElement> | undefined;
  open: boolean;
  title?: string;
  value?: string;
  handleConfirm: React.MouseEventHandler<HTMLButtonElement>;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ handleClose, open, title, value, handleConfirm }) => {
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogTitle id='alert-dialog-title'>{title ?? 'Confirmar exclusão?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Deseja realmente excluir o item: <strong>{value}</strong>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancelar
        </Button>
        <Button onClick={handleConfirm} color='primary' variant='contained' autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
