import { createStyles, makeStyles, Menu, MenuProps, Theme } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popoverMenu: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    popoverPaper: {
      backgroundColor: theme.palette.info.main,
    },
  })
);

const RoundedMenu: React.FC<MenuProps> = ({ children, anchorOrigin, ...props }) => {
  const classes = useStyles();

  return (
    <Menu
      {...props}
      getContentAnchorEl={null}
      keepMounted
      className={classes.popoverMenu}
      classes={{ paper: classes.popoverPaper }}
      anchorOrigin={anchorOrigin ? anchorOrigin : { vertical: 'bottom', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      anchorPosition={{ left: 0, top: 10 }}>
      {children}
    </Menu>
  );
};

export default RoundedMenu;
