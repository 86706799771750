import { Button, ButtonProps, styled, withTheme } from '@material-ui/core';
import React from 'react';
import { IFontWeight } from '../../../utils/types';

export const CustomButton = styled(withTheme(Button))(props => ({
  color: props.theme.palette.grey[100],
  fontWeight: "bold",
  fontFamily: 'Archivo',
  fontSize: '1.25rem',
  textTransform: 'none',
  lineHeight: '2rem',
  padding: '1rem 0',
  boxShadow: props.theme.shadows[3],
  borderRadius: 10,
  width: '100%',
}));

interface MyButtonProps extends IFontWeight {
  color?: string;
}

export const ButtonDynamicProps = styled(({ color, ...other }: MyButtonProps & Omit<ButtonProps, keyof MyButtonProps>) => <Button {...other} />)({
  color: ({color}: MyButtonProps) => color,
  fontWeight: ({fontWeight}: MyButtonProps) => fontWeight ?? 'normal'
});
