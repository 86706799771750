import * as Yup from 'yup';
import useYup from '../hooks/useYup';

export class Salary {
  salaryValue: number = 0;
  businessDays: number = 0;
  businessHours: number = 0;
  dailyRate: number = 0;
  hourRate: number = 0;
  minuteRate: number = 0;

  static from(salary: Salary): Salary {
    const newSalary = new Salary();
    newSalary.salaryValue = +salary.salaryValue;
    newSalary.businessDays = +salary.businessDays;
    newSalary.businessHours = +salary.businessHours;
    return newSalary;
  }
}

export const  useSalaryValidationSchema = () => {
  const { financialValue, businessDays, businessHours } = useYup();

  return Yup.object({
    salaryValue: financialValue,
    businessDays,
    businessHours,
  });
}
