import { Component, ErrorInfo } from 'react';

class ErrorBoundary extends Component {
  public state = {
    redirect: '',
    hasError: false,
  };

  public static getDerivedStateFromError() {
    return { hasError: true };
  }
  // set the types for error  and info
  public componentDidCatch(error: Error, info: ErrorInfo) {
    console.error('ErrorBoundary caught an error', error, info);
  }
  public componentDidUpdate() {
    if (this.state.hasError) {
      setTimeout(() => this.setState({ redirect: true }), 5000);
    }
  }
  public render() {
    if (this.state.redirect) {
      window.location.assign("/dashboard")
    }

    if (this.state.hasError) {
      return (
        <h1>
          Houve um erro nessa página. <a href="/dashboard">Clique aqui</a> para
          voltar à página inicial ou espere 5 segundos.
        </h1>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
