import * as Yup from 'yup';
import useYup from '../hooks/useYup';
import { Expense } from './Expense.entity';

export class BusinessExpense {
  id: string = '';
  businessId: string = '';
  expenseId: string = '';
  cost: number = 0;
  dividedBy: number = 1;
  expense: Expense = new Expense();

  parseNumbers() {
    this.cost = +this.cost;
    this.dividedBy = +this.dividedBy;
  }

  static from(businessExpense: BusinessExpense): BusinessExpense {
    const newInstance = new BusinessExpense();
    newInstance.id = businessExpense.id;
    newInstance.businessId = businessExpense.businessId;
    newInstance.expenseId = businessExpense.expenseId;
    newInstance.expense = businessExpense.expense;
    newInstance.cost = businessExpense.cost;
    newInstance.dividedBy = businessExpense.dividedBy;
    newInstance.parseNumbers();
    return newInstance;
  }
}

export class CreateExpenseDto {
  name: string;
  cost: number;
  dividedBy: number;

  constructor(name: string, cost: number, dividedBy: number) {
    this.name = name;
    this.cost = cost;
    this.dividedBy = dividedBy;
  }

  static fromBusinessExpense(businessExpense: BusinessExpense) {
    return new CreateExpenseDto(businessExpense.expense.name, businessExpense.cost, businessExpense.dividedBy);
  }
}

export const useCreateExpenseDtoValidationSchema = () => {
  const { financialValue, dividedBy } = useYup();
  return Yup.object({
    cost: financialValue,
    dividedBy,
  });
}