import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, TextField } from '@material-ui/core';
import { DeleteOutlined, PlaylistAddOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import useForm from '../../../hooks/useForm';
import { RecipeInstruction } from '../../../model/RecipeInstruction.entity';
import { LightBluePaper } from '../../core/Paper/CustomPaper';

interface NewRecipeFormInstructionsProps {}

const NewRecipeFormInstructions: React.FC<NewRecipeFormInstructionsProps> = () => {
  const [instructions, setInstructions] = useState([] as RecipeInstruction[]);
  const { handleChange, values } = useForm<RecipeInstruction>(new RecipeInstruction());

  function handleAddInstruction() {
    const step = instructions.length + 1;
    const instruction = new RecipeInstruction();
    setInstructions([...instructions, instruction]);
  }

  const handleDeleteInstruction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const updatedInstructions = instructions.filter(i => i.step !== +e.currentTarget.value);
    const length = updatedInstructions.length;

    // ajustar steps
    updatedInstructions.forEach((i, index) => (i.step = length - (length - 1) + index));
    setInstructions(updatedInstructions);
  };

  return (
    <Box paddingY='3%'>
      <Box display='flex' marginY='2%' justifyContent='space-between'>
        <Box width='90%'>
          <TextField multiline margin='dense' id='instructionText' name='text' label='Instrução' type='text' value={values?.instruction} fullWidth onChange={handleChange} />
          <Box display='flex'>
            <TextField style={{ marginRight: '10%' }} margin='dense' id='temperature' name='temperature' label='Temperatura' type='text' fullWidth value={values.temperature} onChange={handleChange} />
            <TextField margin='dense' id='time' name='time' label='Tempo' type='text' fullWidth value={values.duration} onChange={handleChange} />
          </Box>
        </Box>
        <IconButton disabled={instructions.length === 10} onClick={handleAddInstruction} style={{ margin: 'auto 0' }}>
          <PlaylistAddOutlined />
        </IconButton>
      </Box>
      <LightBluePaper hidden={instructions.length === 0}>
        <List dense>
          {instructions.length > 0 &&
            instructions.map(inst => (
              <ListItem key={inst.step}>
                <ListItemText primary={`${inst.step} - ${inst.instruction}`} secondary={`Temp: ${inst.temperature} - Tempo: ${inst.duration}`} />
                <ListItemSecondaryAction>
                  <IconButton edge='end' aria-label='delete' value={inst.step} onClick={handleDeleteInstruction}>
                    <DeleteOutlined />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </LightBluePaper>
    </Box>
  );
};

export default NewRecipeFormInstructions;
