import { Box } from '@material-ui/core';
import React from 'react';
import { BlackTypography } from '../../components/core/Typography/CustomTypography';
import useFormatter from '../../hooks/useFormatter';
import { Recipe } from '../../model/Recipe.entity';
import ProfitTable from './ProfitTable';
import RecipeCostsTable from './RecipeCostsTable';
import SellTable from './SellTable';

interface RecipeFinanceProps {
  recipe: Recipe;
}

const RecipeFinance: React.FC<RecipeFinanceProps> = ({ recipe }) => {
  const { percentageFormatter, dateFormatter, currencyFormatter } = useFormatter();
  const topDataHeaders = ['Tipo', 'Validade', 'Rendimento', 'Tempo(min)', 'Custo Unit.', 'Tx. Cartão', 'Tx. App', 'Atualizado em'];

  const topData = [
    recipe.productType,
    recipe.shelfLife,
    recipe.yield,
    recipe.preparationTime,
    currencyFormatter(recipe.currentUnitPrice),
    percentageFormatter(recipe.businessPaymentMachine && recipe.businessPaymentMachine.getHighestFee()),
    percentageFormatter(recipe.businessFoodApp && recipe.businessFoodApp.fee),
    dateFormatter(new Date(recipe.updatedAt)),
  ];

  return (
    <div>
      <Box padding='1% 2%' borderRadius='borderRadius' bgcolor='info.light' display='grid' gridTemplateColumns='repeat(8, 1fr)' gridGap='10px' justifyItems='center' alignItems='center' marginY='1%'>
        {topDataHeaders.map(header => (
          <BlackTypography key={header} align='center'>
            <strong>{header}</strong>
          </BlackTypography>
        ))}
        {topData.map(data => (
          <BlackTypography key={data} align='center'>
            {data}
          </BlackTypography>
        ))}
      </Box>

      <RecipeCostsTable recipe={recipe} />
      <Box display={'flex'} paddingY='2%'>
        <ProfitTable recipe={recipe} />
        <SellTable recipe={recipe} />
      </Box>
    </div>
  );
};

export default RecipeFinance;
