import { Snackbar } from '@material-ui/core';
import { Alert, AlertProps } from '@material-ui/lab';
import React from 'react';
import { Actions, useAppContext } from '../../../context/AppContext';
import * as AlertEntity from '../../../model/Alert.entity';

export const CustomAlert: React.FC<AlertProps> = props => {
  const { state, dispatch } = useAppContext();

  const handleCloseAlert = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch({ type: Actions.SET_ALERT, payload: { alert: new AlertEntity.Alert() } });
  };

  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={5000} onClose={handleCloseAlert} open={state.alert.open}>
      <Alert onClose={handleCloseAlert} variant='filled' elevation={6} severity={state.alert.severity}>
        {state.alert.message}
      </Alert>
    </Snackbar>
  );
};
