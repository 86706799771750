import { CircularProgress, MobileStepper, useTheme } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonDynamicProps } from '../../components/core/Buttons/CustomButton';
import { BindKeyboardCarousel } from '../../components/core/Carousel/Carousel';
import { BluePaper } from '../../components/core/Paper/CustomPaper';
import { useAppContext } from '../../context/AppContext';
import useAlert from '../../hooks/useAlert';
import { Recipe } from '../../model/Recipe.entity';
import { BusinessService } from '../../services/business.service';
import RecipeFinance from './RecipeFinance';
import RecipeInstructions from './RecipeInstructionsTable';
import RecipeProductsTable from './RecipeProductsTable';
import RecipesContainer from './RecipesContainer';
interface RecipeDetailsProps {}

const RecipeDetails: React.FC<RecipeDetailsProps> = () => {
  const { state } = useAppContext();
  const { user } = state;
  const theme = useTheme();
  const { recipeId } = useParams<{ recipeId: string }>();
  const [activeStep, setActiveStep] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [recipe, setRecipe] = useState<Recipe>(new Recipe());
  const { errorAlert } = useAlert();
  
	useEffect(() => {
		(async () => {
			setIsLoading(true);
			if (recipe) {
				setIsLoading(false);
				return;
			}
			try {
				const recipe = await BusinessService.getRecipeById(user.businesses[0].id, recipeId);
				// const productDialogs = recipes.map(businessProduct => ({ data: businessProduct, isOpen: false } as ConfirmDialogState<BusinessProduct>));
				setRecipe(recipe);
				// setConfirmDialogs(productDialogs);
				// setEditProductDialogs(productDialogs);
			} catch (err) {
				errorAlert(err.message ?? err);
			} finally {
				setIsLoading(false);
			}
		})();

		return () => {
			setIsLoading(false);
		};
	});

  return isLoading ? (<CircularProgress />) : (
    <RecipesContainer title={recipe.name}>
      <BluePaper>
        <BindKeyboardCarousel
          enableMouseEvents
          animateHeight
          index={activeStep}
          onChangeIndex={step => {
            setActiveStep(step);
          }}>
          <RecipeInstructions recipe={recipe} />
          <RecipeFinance recipe={recipe} />
          <RecipeProductsTable recipe={recipe} />
        </BindKeyboardCarousel>
        <MobileStepper
          variant='dots'
          steps={3}
          position='static'
          activeStep={activeStep}
          style={{ backgroundColor: 'unset' }}
          nextButton={
            <ButtonDynamicProps
              color={theme.palette.common.black}
              fontWeight='normal'
              size='small'
              onClick={() => {
                setActiveStep(prevActiveStep => ++prevActiveStep);
              }}
              disabled={activeStep === 2}>
              <KeyboardArrowRight />
            </ButtonDynamicProps>
          }
          backButton={
            <ButtonDynamicProps
              color={theme.palette.common.black}
              size='small'
              onClick={() => {
                setActiveStep(step => --step);
              }}
              disabled={activeStep === 0}>
              <KeyboardArrowLeft />
            </ButtonDynamicProps>
          }
        />
      </BluePaper>
    </RecipesContainer>
  );
};

export default RecipeDetails;
