import * as Yup from 'yup';
import useYup from '../hooks/useYup';
import { Business } from './Business.entity';
import { User } from './User.entity';
export class SignUp {
  confirmEmail: string = '';
  confirmPassword: string = '';
  showPassword: boolean = false;
  showConfirmPassword: boolean = false;
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  password: string = '';
  cpf: string = '';
  businesses?: Business[];

  public static SignUpFromUser(user: User) {
    const signup = new SignUp();
    if (!user) return signup;

    signup.firstName = user.firstName || '';
    signup.lastName = user.lastName || '';
    signup.email = user.email || '';
    signup.password = user.password || '';
    signup.cpf = user.cpf || '';
    signup.confirmEmail = user.email || '';
    signup.confirmPassword = user.password || '';
    signup.showPassword = false;
    signup.showConfirmPassword = false;
    signup.businesses = user.businesses || [];

    return signup;
  }
}

export const useSignUpValidationSchema = () => {
  const { email, password, name, cpf, confirmEmail, confirmPassword } = useYup();

  return Yup.object({
    firstName: name,
    lastName: name,
    cpf,
    email,
    confirmEmail,
    password,
    confirmPassword,
  });
}