import { Button, CircularProgress, IconButton, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Create, DeleteOutlined, EditOutlined } from '@material-ui/icons';
import React, { memo, useEffect, useState } from 'react';
import { CustomPaper } from '../../components/core/Paper/CustomPaper';
import { CellColorInherit, CustomTable, CustomTableRow, LeftTableCell, MiddleTableCell, RightTableCell } from '../../components/core/Table/CustomTable';
import { BlackTypography } from '../../components/core/Typography/CustomTypography';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import NewExpenseDialog from '../../components/dialogs/NewExpenseDialog';
import { useAppContext } from '../../context/AppContext';
import useAlert from '../../hooks/useAlert';
import useFormatter from '../../hooks/useFormatter';
import useMultipleDialogs, { ConfirmDialogState } from '../../hooks/useMultipleDialogs';
import { BusinessExpense } from '../../model/BusinessExpense.entity';
import { BusinessService } from '../../services/business.service';

const calcAverageCostTotal = (arr: BusinessExpense[]) => arr.reduce((previous, current) => previous + +current.cost, 0);
const calcBusinessCostTotal = (arr: BusinessExpense[]) => arr.reduce((previous, current) => previous + current.cost / current.dividedBy, 0);

interface ExpensesTableState {
  expenses: BusinessExpense[];
  averageCostTotal: number;
  businessCostTotal: number;
}

const ExpensesTable: React.FC = () => {
  const { state } = useAppContext();
  const { user } = state;
  const { currencyFormatter } = useFormatter();
  const [data, setData] = useState<ExpensesTableState>({ expenses: [], averageCostTotal: 0, businessCostTotal: 0 });
  const {getDialog, toggleDialog} = useMultipleDialogs<BusinessExpense>();
  const [editExpenseDialogs, setEditExpenseDialogs] = useState<ConfirmDialogState<BusinessExpense>[]>([]);
  const [openNewExpenseDialog, setOpenNewExpenseDialog] = useState(false);
  const { successAlert, errorAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [confirmDialogs, setConfirmDialogs] = useState<ConfirmDialogState<BusinessExpense>[]>([]);
  const [loadExpensesCounter, setLoadExpensesCounter] = useState(0);
  const headers = ['Despesa', 'Valor médio', 'Divisor', 'Valor Empresa', 'Ação'];

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (!user.businesses || data.expenses.length > 0 || loadExpensesCounter > 0) {
        setIsLoading(false);
        return;
      }
      try {
        const expenses = await BusinessService.getBusinessExpenses(user.businesses[0].id);
        setData({ expenses, averageCostTotal: calcAverageCostTotal(expenses), businessCostTotal: calcBusinessCostTotal(expenses) });
        const expenseDialogs = expenses.map(expense => ({ data: expense, isOpen: false } as ConfirmDialogState<BusinessExpense>));
        setConfirmDialogs(expenseDialogs);
        setEditExpenseDialogs(expenseDialogs);
        setLoadExpensesCounter(loadExpensesCounter + 1)
      } catch (err) {
        errorAlert(err.message);
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {
      setIsLoading(false);
    };
  });

  const handleDelete = (expenseId: string) => async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!user.businesses) {
      return;
    }

    try {
      setIsLoading(true);

      const updatedCosts = data.expenses.filter(expense => expense.id !== expenseId);

      await BusinessService.deleteBusinessExpense(user.businesses[0].id, expenseId);

      setData({ expenses: updatedCosts, averageCostTotal: calcAverageCostTotal(updatedCosts), businessCostTotal: calcBusinessCostTotal(updatedCosts) });

      successAlert();
    } catch (err) {
      errorAlert(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    <CircularProgress />
  ) : (
    <TableContainer component={CustomPaper}>
      <CustomTable size='medium' aria-label='Costs table'>
        <TableHead style={{ color: '#000' }}>
          <TableRow>
           {headers.map((header) => (
              <CellColorInherit bold align='center'>
               {header}
              </CellColorInherit>
           ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ color: '#000' }}>
          {data.expenses.length <= 0 ? (
            <CustomTableRow>
              <LeftTableCell colSpan={3} align='center'>
                Nenhum custo definido
              </LeftTableCell>
              <RightTableCell colSpan={2} align='center'>
                <Button startIcon={<Create color='action' />} onClick={() => setOpenNewExpenseDialog(true)}>
                  <BlackTypography variant='body1'>Definir</BlackTypography>
                </Button>
              </RightTableCell>
            </CustomTableRow>
          ): (
            data.expenses.map(expense => (
              <CustomTableRow key={expense.id}>
                <LeftTableCell align='center'>{expense.expense.name}</LeftTableCell>
                <MiddleTableCell align='center'>{currencyFormatter(expense.cost)}</MiddleTableCell>
                <MiddleTableCell align='center'>{expense.dividedBy}</MiddleTableCell>
                <MiddleTableCell align='center'>{currencyFormatter(expense.cost / expense.dividedBy)}</MiddleTableCell>
                <RightTableCell align='center'>
                  <IconButton onClick={toggleDialog(expense.id, editExpenseDialogs, setEditExpenseDialogs)} color={'inherit'}>
                    <EditOutlined />
                  </IconButton>
                  <IconButton onClick={toggleDialog(expense.id, confirmDialogs, setConfirmDialogs)} value={expense.id} color={'inherit'}>
                    <DeleteOutlined />
                  </IconButton>
                </RightTableCell>
                <NewExpenseDialog data={expense} open={getDialog(editExpenseDialogs, expense)} handleClose={toggleDialog(expense.id, editExpenseDialogs, setEditExpenseDialogs)} />
  
                <ConfirmDialog
                  handleConfirm={handleDelete(expense.id)}
                  open={getDialog(confirmDialogs, expense)}
                  handleClose={toggleDialog(expense.id, confirmDialogs, setConfirmDialogs)}
                  value={expense.expense.name}
                />
              </CustomTableRow>
            ))
          )}
          <TableRow>
            <CellColorInherit align='center'>Total</CellColorInherit>
            <CellColorInherit bold align='center'>
              {currencyFormatter(data.averageCostTotal)}
            </CellColorInherit>
            <CellColorInherit />
            <CellColorInherit bold align='center'>
              {currencyFormatter(data.businessCostTotal)}
            </CellColorInherit>
            <CellColorInherit />
          </TableRow>
        </TableBody>
      </CustomTable>
      <NewExpenseDialog open={openNewExpenseDialog} handleClose={() => setOpenNewExpenseDialog(false)} />
    </TableContainer>
  );
};

export default memo(ExpensesTable);
