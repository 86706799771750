/* eslint-disable react-hooks/exhaustive-deps */
import { Button, capitalize, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import ErrorMessage from '../../errors/ErrorMessage';
import useAlert from '../../hooks/useAlert';
import useAppLoading from '../../hooks/useAppLoading';
import { Brand } from '../../model/Brand.entity';
import { Business } from '../../model/Business.entity';
import { BusinessProduct, BusinessProductCategory, useCreateBusinessProductDtoValidationSchema } from '../../model/BusinessProduct.entity';
import { CustomDialogProps } from "../../model/CustomDialogProps";
import { Product, Unit } from '../../model/Product.entity';
import { BrandService } from '../../services/brand.service';
import { BusinessService } from '../../services/business.service';
import { ProductService } from '../../services/product.service';
import { AsyncAutocomplete } from '../core/Autocomplete/AsyncAutocomplete';

const NewProductDialog: React.FC<CustomDialogProps<BusinessProduct>> = ({ data, open, handleClose }) => {
  const { values, handleChange, handleSubmit, setFieldValue, touched, errors } = useFormik({
    initialValues: data ?? new BusinessProduct(),
    onSubmit,
    validationSchema: useCreateBusinessProductDtoValidationSchema(),
  });
  const { state } = useAppContext();
  const { user } = state;
  const { setAppLoading } = useAppLoading();
  const { successAlert, errorAlert } = useAlert();
  // const {LoadBrands, brands, setBrands} = useLoadBrands();
  // const {LoadProducts, products, setProducts} = useLoadProducts(values);
  const [products, setProducts] = useState<Product[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const loadingBrands = brands.length === 0;
  const loadingProducts = products.length === 0;
  
  //LoadBrands(loadingBrands);
  useEffect(() => {
    let active = true;
    if (!loadingBrands || brands.length > 0) {
      return;
    }

    (async () => {
      try {
        const brands = await BrandService.getBrands();

        if (active) {
          setBrands(brands);
        }
      } catch (err) {
        errorAlert(err.message || err);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingBrands]);

  //LoadProducts(loadingProducts);
  useEffect(() => {
    let active = true;

    if (!loadingProducts || products.length > 0) {
      return;
    }

    (async () => {
      try {
      const products = await ProductService.getProducts('');

        if (active) {
          setProducts(products);
        }
      } catch (err) {
        errorAlert(err.message ?? err);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingProducts]);

  async function onSubmit(values: BusinessProduct) {
    try {
      if (user.businesses && (!values.business || !values.business.id)) {
        values.business = new Business();
        values.business.id = user.businesses[0].id;
      }
      setAppLoading(true);

      await BusinessService.createOrUpdateBusinessProduct(values);

      successAlert();
    } catch (err) {
      errorAlert(err.message ?? err);
    } finally {
      setAppLoading(false);
    }
  }
  
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Novo Produto</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>Crie um novo produto para sua empresa</DialogContentText>

          <AsyncAutocomplete
            autoFocus
            freeSolo={true}
            options={products}
            getOptionSelected={(option: Product, value: string) => option.name === value}
            loading={loadingProducts}
            setOptions={setProducts}
            value={values.product}
            label={'Nome do Produto'}
            groupBy={(option: Product) => option.brand.name}
            customGetOptionLabel={(option: Product)  =>  option?.name?.length > 0 ? `${option.name} ${ option.quantity + option.unit}` : ''}
            optionAttribute={'name'}
            id='product'
            onInputChange={(_e, value) => setFieldValue('customProductName', value ?? '')}
            onChange={(_e, value: Product) => {
              setFieldValue('product', value ?? '');
              setFieldValue('customPrice', value?.estimatedPrice ?? '');
            }}
          />
          {touched.product?.name && errors.product?.name && <ErrorMessage errorMessage={errors.product?.name} />}
        
          <AsyncAutocomplete
            freeSolo={true}
            options={brands}
            loading={loadingBrands}
            setOptions={setBrands}
            value={values.product.brand}
            label={'Marca'}
            optionAttribute={'name'}
            id='product.brand'
            onInputChange={(_e, value) => {
              setFieldValue('product.brand.name', value ?? '')
              setFieldValue('product.brand.id', undefined)
            }}
            onChange={(_e, value) => setFieldValue('product.brand.name', value?.name ?? '')}
          />
          {touched.product?.brand?.name && errors.product?.brand?.name && <ErrorMessage errorMessage={errors.product?.brand?.name} />}


          <TextField margin='dense' id='product.quantity' label='Quantidade' type='number' name='product.quantity' fullWidth onChange={handleChange} value={values.product.quantity} />
          {touched.product?.quantity && errors.product?.quantity && <ErrorMessage errorMessage={errors.product?.quantity} />}

          <TextField select margin='dense' id='unit' label='Unidade Medida' type='text' name='product.unit' fullWidth onChange={handleChange} value={values.product.unit}>
            {Object.values(Unit).map(unit => (
              <MenuItem key={unit} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </TextField>
          {touched.product?.unit && errors.product?.unit && <ErrorMessage errorMessage={errors.product?.unit} />}
          <TextField margin='dense' id='price' label='Preço' type='number' name='customPrice' fullWidth onChange={handleChange} value={values.customPrice} />
          {touched.customPrice && errors.customPrice && <ErrorMessage errorMessage={errors.customPrice} />}
          <TextField select margin='dense' id='category' label='Categoria' type='text' name='category' fullWidth onChange={handleChange} value={values.category}>
            {Object.values(BusinessProductCategory).map(category => (
              <MenuItem key={category} value={category}>
                {capitalize(category)}
              </MenuItem>
            ))}
          </TextField>
          {touched.category && errors.category && <ErrorMessage errorMessage={errors.category} />}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancelar
          </Button>
          <Button type='submit' variant='contained' color='primary'>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(NewProductDialog);