import { Box } from '@material-ui/core';
import React from 'react';
import DashboardContainer from '../../components/containers/DashboardContainer/DashboardContainer';

const RecipesContainer: React.FC<{
  title: string;
}> = ({ children, title }) => {
  return (
    <DashboardContainer page={'recipes'} title={title}>
      <Box marginY='2%'>{children}</Box>
    </DashboardContainer>
  );
};

export default RecipesContainer;
