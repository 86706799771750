import { Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AssignmentOutlined, ChevronLeftOutlined, CreditCardOutlined, HomeOutlined, HourglassEmptyOutlined, MenuBookOutlined, MenuOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Actions, AppContext } from '../../../context/AppContext';
import usePages, { Page } from './usePages';
import { useStyles } from './useStyles';

const DrawerMenu = ({ activeMenu }: { activeMenu: string }) => {
  const pages = usePages();
  const { dispatch, state } = useContext(AppContext);
  const classes = useStyles();

  function isActive(page: Page, activeMenu: string): any {
    return page.route === activeMenu;
  }

  const handleDrawerOpen = () => {
    dispatch({ type: Actions.CHANGE_DRAWER, payload: { value: true } });
  };
  const handleDrawerClose = () => {
    dispatch({ type: Actions.CHANGE_DRAWER, payload: { value: false } });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer)}
        classes={{
          paper: clsx({ [classes.drawerOpen]: state.drawerOpened, [classes.drawerClose]: !state.drawerOpened }),
        }}>
        <div className={classes.toolbar}>
          {state.drawerOpened ? (
            <IconButton onClick={handleDrawerClose} className={classes.listItemIcon}>
              <ChevronLeftOutlined />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen} className={clsx(classes.listItemIcon, classes.listItemIconActive)}>
              <MenuOutlined />
            </IconButton>
          )}
        </div>
        <Divider />
        <List>
          {pages.map((page, index) => (
            <ListItem
              button
              key={page.route}
              classes={{
                gutters: clsx(classes.listItemGutters, {
                  [classes.listItemGuttersOpen]: state.drawerOpened,
                  [classes.listItemGuttersClose]: !state.drawerOpened,
                }),
              }}>
              <Link to={`/${page.route}`} className={classes.link}>
                <div
                  className={clsx(classes.listItem, {
                    [classes.active]: isActive(page, activeMenu),
                    [classes.activeAndOpened]: isActive(page, activeMenu) && state.drawerOpened,
                  })}>
                  <ListItemIcon className={clsx(classes.listItemIcon, { [classes.listItemIconActive]: !state.drawerOpened })}>
                    {index === 0 && <HomeOutlined />}
                    {index === 1 && <CreditCardOutlined />}
                    {index === 2 && <AssignmentOutlined />}
                    {index === 3 && <MenuBookOutlined />}
                  </ListItemIcon>
                  <ListItemText classes={{ primary: classes.listItemText }} className={clsx({ [classes.hide]: !state.drawerOpened })} primary={page.title} />
                </div>
              </Link>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['Estoque', 'Clientes', 'Vendas', 'Orçamentos'].map((text, index) => (
            <ListItem
              key={text}
              disabled={true}
              classes={{
                gutters: clsx(classes.listItemGutters, {
                  [classes.listItemGuttersOpen]: state.drawerOpened,
                  [classes.listItemGuttersClose]: !state.drawerOpened,
                }),
              }}>
              <div className={clsx(classes.listItem, { [classes.active]: text === activeMenu })}>
                <ListItemIcon className={clsx(classes.listItemIcon, { [classes.listItemIconActive]: !state.drawerOpened })}>{<HourglassEmptyOutlined />}</ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemText }} primary={text} className={clsx({ [classes.hide]: !state.drawerOpened })} />
              </div>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default DrawerMenu;
