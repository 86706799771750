import { Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { LightBluePaperFit } from '../../components/core/Paper/CustomPaper';
import { BgDarkTableCell, CustomTablePadded, CustomTableRow, LeftTableCell, RightTableCell } from '../../components/core/Table/CustomTable';
import useFormatter from '../../hooks/useFormatter';
import { Recipe } from '../../model/Recipe.entity';

interface ProfitTableProps {
  recipe: Recipe;
}

const ProfitTable: React.FC<ProfitTableProps> = ({ recipe }) => {
  const { currencyFormatter, percentageFormatter } = useFormatter();
  const rows = [
    { title: 'Lucro Desejado %', data: percentageFormatter(recipe.desiredProfit/ 100) },
    { title: 'Lucro R$', data: currencyFormatter(recipe.currentUnitProfit * recipe.yield) },
    { title: 'Lucro/Unidade', data: currencyFormatter(recipe.currentUnitProfit) },
  ];
  return (
    <TableContainer style={{ marginRight: '1%' }} component={LightBluePaperFit}>
      <Table size='small' aria-label='Profit table'>
        <TableHead style={{ color: '#000' }}>
          <TableRow>
            <BgDarkTableCell colSpan={2} bold align='center'>
              Lucro
            </BgDarkTableCell>
          </TableRow>
        </TableHead>
        <TableBody style={{ color: '#000' }}>
          <CustomTablePadded size='medium' aria-label='Profit table body'>
            <TableBody style={{ color: '#000' }}>
              {rows.map(row => (
                <CustomTableRow key={row.title}>
                  <LeftTableCell bold align='center'>
                    {row.title}
                  </LeftTableCell>
                  <RightTableCell align='center'>{row.data}</RightTableCell>
                </CustomTableRow>
              ))}
            </TableBody>
          </CustomTablePadded>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProfitTable;
