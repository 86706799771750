import { TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { LightBluePaper } from '../../components/core/Paper/CustomPaper';
import { CellColorInherit, CustomTable, CustomTableRow, LeftTableCell, MiddleTableCell, RightTableCell } from '../../components/core/Table/CustomTable';
import useFormatter from '../../hooks/useFormatter';
import { Recipe } from '../../model/Recipe.entity';

interface RecipeCostsTableProps {
  recipe: Recipe;
}

interface RecipeCostsRow {
  title: string;
  recipeCost: number;
  percent: number;
  unitCost: number;
}

const headers = ['Projeção Financeira', 'Custo Receita', '%', 'Custo Unitário'];

const RecipeCostsTable: React.FC<RecipeCostsTableProps> = ({ recipe }) => {
  const { currencyFormatter, percentageFormatter } = useFormatter();
  const rows: RecipeCostsRow[] = [
    { title: 'Mão de Obra', recipeCost: 3.9, percent: 0.53, unitCost: 0.08 },
    { title: 'Insumos', recipeCost: 3.9, percent: 0.17, unitCost: 0.08 },
    { title: 'Embalagem', recipeCost: 3.9, percent: 0, unitCost: 0.08 },
    { title: 'Custos Fixos/Variáveis', recipeCost: 10.31, percent: 0.29, unitCost: 0.08 },
    { title: 'TOTAL', recipeCost: 34, percent: 1, unitCost: 1.5 },
  ];

  return (
    <TableContainer component={LightBluePaper}>
      <CustomTable size='medium' aria-label='Costs table'>
        <TableHead style={{ color: '#000' }}>
          <TableRow>
            {headers.map(header => (
              <CellColorInherit key={header} bold align='center'>
                {header}
              </CellColorInherit>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ color: '#000' }}>
          {rows.map(row => (
            <CustomTableRow key={row.title}>
              <LeftTableCell bold={row.title === 'TOTAL'} align='center'>
                {row.title}
              </LeftTableCell>
              <MiddleTableCell bold={row.title === 'TOTAL'} align='center'>
                {currencyFormatter(row.recipeCost)}
              </MiddleTableCell>
              <MiddleTableCell bold={row.title === 'TOTAL'} align='center'>
                {percentageFormatter(row.percent)}
              </MiddleTableCell>
              <RightTableCell bold={row.title === 'TOTAL'} align='center'>
                {currencyFormatter(row.unitCost)}
              </RightTableCell>
            </CustomTableRow>
          ))}
        </TableBody>
      </CustomTable>
    </TableContainer>
  );
};

export default RecipeCostsTable;
