import * as Yup from 'yup';
import { useNameOnlyValidationSchema } from "../hooks/useNameOnlyValidationSchema";
import useYup from "../hooks/useYup";
import { PaymentMachineManufacturer } from "./PaymentMachineManufacturer.entity";

export class PaymentMachine {
  id?: string = '';
  name: string = '';
  manufacturer: PaymentMachineManufacturer = new PaymentMachineManufacturer();
}

export const usePaymentMachineValidationSchema = () => {
  const {name} = useYup();
  const manufacturer = useNameOnlyValidationSchema();
  
  return Yup.object({
    name,
    manufacturer
  });
}