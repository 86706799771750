import React from 'react'
import { IForm } from '../../../model/Form.entity'
import './Form.css'

const Form = ({onSubmit, children, className = ""}: IForm) => {
  return (
    <form className={"login_form " + className} onSubmit={onSubmit}>
      {children}
    </form>
  )
}

export default Form;
