import { BusinessOutlined } from '@material-ui/icons';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Actions, useAppContext } from '../../../context/AppContext';
import ErrorMessage from '../../../errors/ErrorMessage';
import useForm from '../../../hooks/useForm';
import { Business, useBusinessValidationSchema } from '../../../model/Business.entity';
import { User } from '../../../model/User.entity';
import { Auth } from '../../../services/auth.service';
import InputContainer from '../../containers/InputContainer/InputContainer';
import { CustomButton } from '../../core/Buttons/CustomButton';
import Form from '../../core/Form/Form';
import Input from '../../core/Input/Input';

const SignUpEmpresaForm = () => {
  const { location, replace } = useHistory();
  const { state } = location as { state: User };
  const { dispatch } = useAppContext();
  const [errorMessage, setErrorMessage] = useState('');
  const { setValue, values: tempValues } = useForm(new Business());

  const { values, handleChange, errors, touched, handleSubmit } = useFormik({
    initialValues: state && state.businesses && state.businesses.length > 0 ? state.businesses[0] : new Business(),
    validationSchema: useBusinessValidationSchema(),
    onSubmit,
  });
  const { businessName, cnpj } = values;

  async function onSubmit(business: Business) {
    try {
      state.businesses = [business];

      const user = await Auth.signup(state);

      dispatch({ type: Actions.SET_USER, payload: { user } });

      replace('/dashboard');
    } catch (error) {
      console.log(error.message);

      setErrorMessage(error.message);
    }
  }

  const customOnChange = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.currentTarget.name, e.target.value);
    state.businesses = [tempValues];
    handleChange(e);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputContainer icon={<BusinessOutlined color='disabled' fontSize='inherit' />}>
        <Input label='Razão Social' type='text' id='businessName' name='businessName' value={businessName} onChange={customOnChange} />
        {touched.businessName && errors.businessName && <ErrorMessage errorMessage={errors.businessName} />}

        <Input label='CNPJ' type='text' id='cnpj' name='cnpj' value={cnpj} onChange={customOnChange} />
        {touched.cnpj && errors.cnpj && <ErrorMessage errorMessage={errors.cnpj} />}
      </InputContainer>

      {errorMessage && errorMessage.split(',').map(error => <ErrorMessage errorMessage={error} />)}

      <CustomButton type='submit' variant='contained' color='secondary' size='large' fullWidth>
        Cadastrar
      </CustomButton>
    </Form>
  );
};

export default SignUpEmpresaForm;
