import { Typography } from '@material-ui/core';
import React from 'react';

export const DivisorTooltip: React.FC = () => {
  return (
    <>
      <Typography align='center' variant='h5'>
        Divisão dos Custos
      </Typography>
      <ul style={{ listStyleType: 'none' }}>
        <li style={{ margin: '2%' }}>
          <Typography variant='body1'>1 - Utilize quando o negócio consome 100% das desepesas do local</Typography>
        </li>
        <li style={{ margin: '2%' }}>
          <Typography variant='body1'>2 - Utilize quando você dedica 50% do seu tempo para o negócio</Typography>
        </li>
        <li style={{ margin: '2%' }}>
          <Typography variant='body1'>3 - Utilize quando você não tem muita dedicação para o seu negócio ou seja, das 24 horas do dia podemos ponderar</Typography>

          <ul style={{ listStyle: 'none' }}>
            <li style={{ margin: '2%' }}>
              <Typography variant='caption'> - 8 horas trabalhadas</Typography>
            </li>
            <li style={{ margin: '2%' }}>
              <Typography variant='caption'> - 8 horas consumo familia</Typography>
            </li>
            <li style={{ margin: '2%' }}>
              <Typography variant='caption'> - 8 horas sem utilização</Typography>
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
};
