import { Box, TextField } from '@material-ui/core';
import React from 'react';
import { Recipe } from '../../../model/Recipe.entity';

interface NewRecipeFormMainProps {
  recipe: Recipe;
}

const NewRecipeFormMain: React.FC<NewRecipeFormMainProps> = ({ recipe }) => {
  return (
    <Box paddingY='3%'>
      <TextField autoFocus margin='dense' id='name' label='Nome da Receita' type='text' fullWidth value={recipe?.name} />
      <TextField margin='dense' id='type' label='Tipo de Receita' type='text' fullWidth value={recipe?.productType} />
      <TextField margin='dense' id='recipeYield' label='Rendimento' type='number' fullWidth value={recipe?.yield} />
      <TextField margin='dense' id='preparationTime' label='Tempo de Preparo' type='number' fullWidth value={recipe?.preparationTime} />
      <TextField margin='dense' id='desiredProfit' label='Lucro Desejado' type='number' fullWidth value={recipe?.desiredProfit} />
      <TextField margin='dense' id='shelfLife' label='Validade' type='text' fullWidth value={recipe?.shelfLife} />
    </Box>
  );
};

export default NewRecipeFormMain;
