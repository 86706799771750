import * as Yup from 'yup';
import { useNameOnlyValidationSchema } from '../hooks/useNameOnlyValidationSchema';
import useYup from '../hooks/useYup';
import { Brand } from './Brand.entity';

export enum Unit {
  KG = 'kg',
  G = 'g',
  MG = 'mg',
  L = 'l',
  ML = 'ml',
  UN = 'un',
}

export class Product {
  id: string = '';
  brand: Brand = new Brand();
  name: string = '';
  quantity: number = 0;
  unit: Unit = Unit.G;
  estimatedPrice?: number = 0;
}

export const useProductValidationSchema = () => {
  const {financialValue, unit, name} = useYup();
  const brand = useNameOnlyValidationSchema();
  
  return Yup.object({
    name,
    unit,
    quantity: financialValue,
    brand
  });
}
