import { Backdrop, CircularProgress } from '@material-ui/core';
import React from 'react';
import { useAppContext } from '../../../context/AppContext';

export const LoadingBackrop: React.FC = () => {
  const { state } = useAppContext();

  return (
    <Backdrop open={state.isAppLoading}>
      <CircularProgress color={'primary'} />
    </Backdrop>
  );
};
