import { PaymentMachine } from "../model/PaymentMachine.entity";
import { PaymentMachineManufacturer } from "../model/PaymentMachineManufacturer.entity";
import { paymentMachines } from "./precin_api";

export const PaymentMachineService = {
  async getManufacturers(): Promise<PaymentMachineManufacturer[]> {
    try {
      const { data } = await paymentMachines.get<PaymentMachineManufacturer[]>('/manufacturers');
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },
  async getPaymentMachines(): Promise<PaymentMachine[]> {
    try {
      const { data } = await paymentMachines.get<PaymentMachine[]>('');
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },
}