import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React from 'react';

const TogglePasswordIcon = ({ toggle, attribute, setValue }: { toggle?: boolean; setValue: Function; attribute?: string }) => {
  const color = 'disabled';
  return (
    <IconButton aria-label='toggle password visibility' onClick={() => setValue(attribute, !toggle)} onMouseDown={e => e.preventDefault()} edge='end'>
      {toggle ? <VisibilityOff color={color} /> : <Visibility color={color} />}
    </IconButton>
  );
};

export default TogglePasswordIcon;
