/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Grid, IconButton, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Create, DeleteOutlined, EditOutlined, Star } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { CustomPaper } from "../../components/core/Paper/CustomPaper";
import { CellColorInherit, CustomTable, CustomTableRow, LeftTableCell, MiddleTableCell, RightTableCell } from "../../components/core/Table/CustomTable";
import { BlackTypography } from "../../components/core/Typography/CustomTypography";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import NewFoodAppDialog from "../../components/dialogs/NewFoodAppDialog";
import { useAppContext } from "../../context/AppContext";
import useAlert from "../../hooks/useAlert";
import useFormatter from "../../hooks/useFormatter";
import useMultipleDialogs, { ConfirmDialogState } from "../../hooks/useMultipleDialogs";
import { BusinessFoodApp } from "../../model/BusinessFoodApp.entity";
import { BusinessService } from "../../services/business.service";

interface FoodAppsTableState {
  bFoodApps: BusinessFoodApp[];
}

const FoodAppsTable: React.FC = () => {
  const { state } = useAppContext();
  const { user } = state;
  const [isLoading, setIsLoading] = useState(true);
  const headers = ['Aplicativo', 'Taxa', 'Ação'];
  const [data, setData] = useState<FoodAppsTableState>({ bFoodApps: [] });
  const { successAlert, errorAlert } = useAlert();
  const {getDialog, toggleDialog} = useMultipleDialogs<BusinessFoodApp>();
  const {percentageFormatter} = useFormatter();
  const [confirmDialogs, setConfirmDialogs] = useState<ConfirmDialogState<BusinessFoodApp>[]>([]);
  const [editFoodAppDialogs, setEditFoodAppDialogs] = useState<ConfirmDialogState<BusinessFoodApp>[]>([]);
  const [openFoodAppDialog, setOpenFoodAppDialog] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (!user.businesses || data.bFoodApps.length > 0) {
        setIsLoading(false);
        return;
      }
      try {
        const bFoodApps = await BusinessService.getBusinessFoodApps(user.businesses[0].id);
        const mainBFoodApp = bFoodApps.find(bFoodApp => bFoodApp.id === user.businesses[0].businessPreference?.mainBusinessFoodApp.id);
        if(mainBFoodApp){
          mainBFoodApp.main = true
        }
        setData({ bFoodApps });
        const bFoodAppsDialogs = bFoodApps.map(bFoodApp => ({ data: bFoodApp, isOpen: false } as ConfirmDialogState<BusinessFoodApp>));
        setConfirmDialogs(bFoodAppsDialogs);
        setEditFoodAppDialogs(bFoodAppsDialogs);
      } catch (err) {
        errorAlert(err.message);
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {
      setIsLoading(false);
    };
  }, [])

  const handleDelete = (bFoodAppId: string) => async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!user.businesses) {
      return;
    }

    try {
      setIsLoading(true);

      const updatedBFoodApps = data.bFoodApps.filter(bFoodApp => bFoodApp.id !== bFoodAppId);

      await BusinessService.deleteBusinessFoodApp(user.businesses[0].id, bFoodAppId);

      setData({ bFoodApps: updatedBFoodApps });

      successAlert();
    } catch (err) {
      errorAlert(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? (
		<CircularProgress />
	) : (
		<TableContainer component={CustomPaper}>
			<CustomTable size='medium' aria-label='Food Apps Table'>
				<TableHead style={{ color: '#000' }}>
					<TableRow>
						{headers.map(header => (
							<CellColorInherit bold align='center'>
								{header}
							</CellColorInherit>
						))}
					</TableRow>
				</TableHead>
				<TableBody style={{ color: '#000' }}>
					{data.bFoodApps.length > 0 ? (
						data.bFoodApps.map(bFoodApp => (
							<CustomTableRow key={bFoodApp.id}>
								<LeftTableCell align='center'>
									<Grid container wrap='nowrap' spacing={1} justifyContent='center' alignItems='center' alignContent='center'>
										{bFoodApp.main && (
											<Grid item>
												<Star color='primary' />
											</Grid>
										)}
										<Grid item>{bFoodApp.foodApp.name}</Grid>
									</Grid>
								</LeftTableCell>
								<MiddleTableCell align='center'>{percentageFormatter(bFoodApp.fee / 100)}</MiddleTableCell>
								<RightTableCell align='center'>
									<IconButton onClick={toggleDialog(bFoodApp.id, editFoodAppDialogs, setEditFoodAppDialogs)} color={'inherit'}>
										<EditOutlined />
									</IconButton>
									<IconButton onClick={toggleDialog(bFoodApp.id, confirmDialogs, setConfirmDialogs)} value={bFoodApp.id} color={'inherit'}>
										<DeleteOutlined />
									</IconButton>
								</RightTableCell>
								<NewFoodAppDialog data={bFoodApp} open={getDialog(editFoodAppDialogs, bFoodApp)} handleClose={toggleDialog(bFoodApp.id, editFoodAppDialogs, setEditFoodAppDialogs)} />
								<ConfirmDialog handleConfirm={handleDelete(bFoodApp.id)} open={getDialog(confirmDialogs, bFoodApp)} handleClose={toggleDialog(bFoodApp.id, confirmDialogs, setConfirmDialogs)} value={bFoodApp.foodApp.name} />
							</CustomTableRow>
						))
					) : (
						<CustomTableRow>
							<LeftTableCell colSpan={1} align='center'>
								Nenhumm Aplicativo Registrado
							</LeftTableCell>
							<RightTableCell colSpan={2} align='center'>
								<Button startIcon={<Create color='action' />} onClick={() => setOpenFoodAppDialog(true)}>
									<BlackTypography>Registrar</BlackTypography>
								</Button>
							</RightTableCell>
						</CustomTableRow>
					)}
				</TableBody>
			</CustomTable>
			<NewFoodAppDialog open={openFoodAppDialog} handleClose={() => setOpenFoodAppDialog(false)} />
		</TableContainer>
	);
}

export default FoodAppsTable;