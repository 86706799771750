import { Accordion, AccordionDetails, AccordionSummary, styled, Typography, withTheme } from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import React from 'react';

export const CustomAccordion = styled(withTheme(Accordion))(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  borderRadius: theme.shape.borderRadius,
}));

export const CustomAccordionSummary = styled(withTheme(AccordionSummary))(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.common.black,
}));

interface MyAccordionProps {
  summaryText: string;
}

export const MyAccordion: React.FC<MyAccordionProps> = ({ children, summaryText }) => {
  return (
    <CustomAccordion>
      <CustomAccordionSummary expandIcon={<ExpandMoreOutlined />}>
        <Typography color='inherit'>
          <strong>{summaryText}</strong>
        </Typography>
      </CustomAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </CustomAccordion>
  );
};
