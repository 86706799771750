import { useAppContext } from '../context/AppContext';
import { CURRENCY } from '../utils/locale';

const useFormatter = () => {
  const { state } = useAppContext();

  const currencyFormatter = new Intl.NumberFormat(state.locale, { style: 'currency', currency: CURRENCY[state.locale] }).format;
  const percentageFormatter = new Intl.NumberFormat(state.locale, { style: 'percent', minimumFractionDigits: 2 }).format;
  const dateFormatter = new Intl.DateTimeFormat(state.locale).format;

  return { currencyFormatter, percentageFormatter, dateFormatter };
};

export default useFormatter;