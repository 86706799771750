/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Switch, TextField, Tooltip } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { useFormik } from "formik";
import { memo, useEffect, useState } from "react";
import { Actions, useAppContext } from "../../context/AppContext";
import ErrorMessage from '../../errors/ErrorMessage';
import useAlert from "../../hooks/useAlert";
import useAppLoading from "../../hooks/useAppLoading";
import useYup from "../../hooks/useYup";
import { Business } from "../../model/Business.entity";
import { BusinessPaymentMachine, useCreateBusinessPaymentMachineValidationSchema } from "../../model/BusinessPaymentMachine.entity";
import { CustomDialogProps } from "../../model/CustomDialogProps";
import { PaymentMachine } from "../../model/PaymentMachine.entity";
import { PaymentMachineManufacturer } from "../../model/PaymentMachineManufacturer.entity";
import { BusinessService } from "../../services/business.service";
import { PaymentMachineService } from "../../services/paymentMachine.service";
import { AsyncAutocomplete } from "../core/Autocomplete/AsyncAutocomplete";

const NewPaymentMachineDialog: React.FC<CustomDialogProps<BusinessPaymentMachine>> = ({ data, open, handleClose }) => {
  const { values, handleChange, handleSubmit, setFieldValue, touched, errors } = useFormik({
    initialValues: data ?? new BusinessPaymentMachine(),
    onSubmit,
    validationSchema: useCreateBusinessPaymentMachineValidationSchema(),
  });
  const { state, dispatch } = useAppContext();
  const { user } = state;
  const { setAppLoading } = useAppLoading();
  const { successAlert, errorAlert } = useAlert();
  const {validateName} = useYup();

  const [paymentMachines, setPaymentMachines] = useState<PaymentMachine[]>([]);
  const [manufacturers, setManufactures] = useState<PaymentMachineManufacturer[]>([]);
  const [manufacturerInputValue, setManufacturerInputValue] = useState('');
  const [paymentMachineNameInputValue, setPaymentMachineNameInputValue] = useState('');

  const loadingManufacturers = manufacturers.length === 0;
  const loadingPaymentMachines = paymentMachines.length === 0;

  // Load all manufacturers
  useEffect(() => {
    let active = true;
    if (!loadingManufacturers || manufacturers.length > 0) {
      return;
    }

    (async () => {
      try {
        const manufacturers = await PaymentMachineService.getManufacturers();

        if (active) {
          setManufactures(manufacturers);
        }
      } catch (err) {
        errorAlert(err.message || err);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingManufacturers]);
  
  // Load all payment machines
  useEffect(() => {
    let active = true;

    if (!loadingPaymentMachines || paymentMachines.length > 0) {
      return;
    }

    (async () => {
      try {
      const paymentMachines = await PaymentMachineService.getPaymentMachines();

        if (active) {
          setPaymentMachines(paymentMachines);
        }
      } catch (err) {
        errorAlert(err.message ?? err);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingPaymentMachines]);

  async function onSubmit(values: BusinessPaymentMachine) {
    try {
      if (user.businesses && (!values.business || !values.business.id)) {
        values.business = new Business();
        values.business.id = user.businesses[0].id;
      }
      setAppLoading(true);

      if(manufacturerInputValue) {
        values.paymentMachine.manufacturer.name = manufacturerInputValue;
        values.paymentMachine.manufacturer.id = undefined;
      }
      await validateName(values.paymentMachine.manufacturer.name, 'brandName');
      if(paymentMachineNameInputValue) {
        values.paymentMachine.name = paymentMachineNameInputValue;
        values.paymentMachine.id = undefined;
      }
      await validateName(values.paymentMachine.name, 'firstName');

      const businessPaymentMachine = await BusinessService.createOrUpdateBusinessPaymentMachine(values);
      if(values.main){
        dispatch({ type: Actions.CHANGE_MAIN_BUSINESS_PAYMENT_MACHINE, payload: { businessPaymentMachine }})
      }
      successAlert();
    } catch (err) {
      errorAlert(err.message ?? err);
    } finally {
      setAppLoading(false);
    }
  }
  
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='new-payment-machine-dialog'>
      <DialogTitle id='new-payment-machine-dialog'>Nova Máquina de Pagamento</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>Crie uma nova máquina de pagamento para a sua empresa</DialogContentText>
          <AsyncAutocomplete
            autoFocus
            freeSolo={true}
            options={manufacturers}
            loading={loadingManufacturers}
            setOptions={setManufactures}
            value={values.paymentMachine.manufacturer}
            label={'Marca/Fabricante'}
            optionAttribute={'name'}
            id='paymentMachine.manufacturer'
            onChange={(_e, value: PaymentMachineManufacturer) => setFieldValue('paymentMachine.manufacturer.name', value?.name ?? '')}
            onInputChange={(_e, value) => {
              setManufacturerInputValue(value)
            }}
          />          

          <AsyncAutocomplete
            freeSolo={true}
            options={paymentMachines}
            getOptionSelected={(option: PaymentMachine, value: string) => option.name === value}
            loading={loadingPaymentMachines}
            setOptions={setPaymentMachines}
            value={values.paymentMachine}
            label={'Nome da Máquina de Pagamento'}
            groupBy={(option: PaymentMachine) => option.manufacturer.name}
            optionAttribute={'name'}
            id='paymentMachine'
            onInputChange={(_e, value) => setPaymentMachineNameInputValue(value)}
            onChange={(_e, value: PaymentMachine) => {
              setFieldValue('paymentMachine.name', value?.name ?? '');
            }}
          />

          <TextField margin='dense' id='debitFee' label='Taxa de Débito' type='number' name='debitFee' fullWidth onChange={handleChange} value={values.debitFee} />
          {touched.debitFee && errors.debitFee && <ErrorMessage errorMessage={errors.debitFee} />}

          <TextField margin='dense' id='creditFee' label='Taxa de Crédito' type='number' name='creditFee' fullWidth onChange={handleChange} value={values.creditFee} />
          {touched.creditFee && errors.creditFee && <ErrorMessage errorMessage={errors.creditFee} />}

          <Box display='flex' alignItems='center'>
          <FormControlLabel control={<Switch checked={values.main} id='main' onChange={handleChange} name="main" />} label="Principal?"/>
          <Tooltip placement='right' title="Ao definir a máquina como principal, ela será automáticamente escolhida ao criar novas receitas.">
                  <HelpOutline fontSize="small"  />
          </Tooltip>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancelar
          </Button>
          <Button type='submit' variant='contained' color='primary'>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default memo(NewPaymentMachineDialog);