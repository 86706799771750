import { Product } from '../model/Product.entity';
import { products } from './precin_api';

export const ProductService = {
  async getProducts(productName: string) {
    try {
      const { data } = await products.get<Product[]>('?productName=' + productName);
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },
};
