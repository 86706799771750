import { AppBar, Button, InputAdornment, TextField, Toolbar, Typography } from '@material-ui/core';
import {
  AccountBalanceWalletOutlined,
  AccountCircleOutlined,
  Brightness4Rounded,
  ExitToAppOutlined,
  HelpOutlineOutlined,
  NotificationsOutlined,
  SearchOutlined,
  SettingsOutlined,
} from '@material-ui/icons';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../../assets/img/logo-novo.png';
import { Actions, AppContext } from '../../../context/AppContext';
import useAppLoading from '../../../hooks/useAppLoading';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { Alert } from '../../../model/Alert.entity';
import { User } from '../../../model/User.entity';
import { Auth } from '../../../services/auth.service';
import SalaryDialog from '../../dialogs/SalaryDialog';
import { ThemeDialog } from '../../dialogs/ThemeDialog';
import { useStyles } from '../DrawerMenu/useStyles';
import IconMenuItem from '../RoundedMenu/IconMenuItem';
import RoundedMenu from '../RoundedMenu/RoundedMenu';

interface Recipe {
  name: string;
  productType: string;
  price: string;
}
const recipes: Recipe[] = [
  {
    name: 'Brigadeiro',
    productType: 'Doces',
    price: '30.00',
  },
  {
    name: 'Bolo',
    productType: 'Doces',
    price: '30.00',
  },
  {
    name: 'Coxinha',
    productType: 'Salgados',
    price: '30.00',
  },
];

const TopBar = () => {
  const { state, dispatch } = useContext(AppContext);
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const { replace } = useHistory();
  const [openSalaryDialog, setOpenSalaryDialog] = useState<boolean>(false);
  const [openThemeDialog, setOpenThemeDialog] = useState<boolean>(false);
  const { setAppLoading } = useAppLoading();

  const { handleSubmit } = useFormik({
    initialValues: { search: '' },
    onSubmit: values => alert(JSON.stringify(values)),
  });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement | Element>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      setAppLoading(true);
      await Auth.signout();
      dispatch({ type: Actions.SET_USER, payload: { user: new User() } });
      setAppLoading(false);
      replace('/');
    } catch (error) {
      dispatch({ type: Actions.SET_ALERT, payload: { alert: Alert.from({ open: true, message: error.message, severity: 'error' }) } });
      setAppLoading(false);
    }
  };

  return (
    <AppBar color='transparent' position='fixed' className={clsx(classes.appBar, { [classes.appBarShift]: state.drawerOpened })}>
      <Toolbar className={classes.topBar}>
        <Link className={classes.logoLink} to={'/dashboard'}>
          <img src={logo} alt='logo' className={classes.logo} />
        </Link>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Autocomplete
            id='search'
            options={recipes}
            getOptionLabel={(option: Recipe) => option.name}
            groupBy={(option: Recipe) => option.productType}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField
                {...params}
                variant='outlined'
                label={
                  <Typography variant={'body2'} noWrap>
                    Procurar
                  </Typography>
                }
                InputProps={{
                  ...params.InputProps,
                  // style: { fontSize: '1.5rem' },
                  endAdornment: width > 500 && (
                    <InputAdornment position='start'>
                      <SearchOutlined color='disabled' />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </form>

        <section className={classes.topBarSection}>
          <div className={classes.iconDiv}>
            <NotificationsOutlined color={'disabled'} className={classes.topBarIcons} />
            <SettingsOutlined color={'disabled'} className={`${classes.topBarMiddleIcon} ${classes.topBarIcons}`} />
            <HelpOutlineOutlined color={'disabled'} className={classes.topBarIcons} />
          </div>

          <Button aria-controls='user-menu' aria-haspopup='true' onClick={handleClick}>
            <div className={classes.userMenu}>
              <Typography variant={'h4'} color={'textPrimary'} className={classes.userName}>
                <strong>{state.user.firstName}</strong>
              </Typography>
              <AccountCircleOutlined color={'disabled'} className={classes.userIcon} />
            </div>
          </Button>
          <RoundedMenu id='user-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            {/* <MenuItem className={classes.userMenuItem}  onClick={handleClose}>Perfil</MenuItem> */}
            {/* <MenuItem className={classes.userMenuItem} onClick={handleClose}>Minha conta</MenuItem> */}
            <IconMenuItem text='Editar Salário' icon={AccountBalanceWalletOutlined} onClick={() => setOpenSalaryDialog(true)} />
            <IconMenuItem text='Definir Tema' icon={Brightness4Rounded} onClick={() => setOpenThemeDialog(true)} />
            <IconMenuItem text='Sair' icon={ExitToAppOutlined} onClick={handleLogout} />
          </RoundedMenu>
        </section>
      </Toolbar>
      <SalaryDialog open={openSalaryDialog} handleClose={() => setOpenSalaryDialog(false)} />
      <ThemeDialog open={openThemeDialog} handleClose={() => setOpenThemeDialog(false)} />
    </AppBar>
  );
};

export default TopBar;
