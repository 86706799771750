import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { PrivateRoute } from './components/auth/PrivateRoute';
import { LoadingRoute } from './components/core/Loading/LoadingRoute';
import useWindowDimensions from './hooks/useWindowDimensions';
import Finances from './pages/Finances/Finances';
import DashboardHome from './pages/Home/Home';
import Ingredients from './pages/Ingredients/Ingredients';
import LoginHome from './pages/LoginHome/LoginHome';
import RecipeDetails from './pages/Recipes/RecipeDetails';
import RecipesTable from './pages/Recipes/RecipesTable';
import SignIn from './pages/SignIn/SignIn';
import SignUp from './pages/SignUp/SignUp';
import SignUpEmpresa from './pages/SignUp/SignUpEmpresa';

const Routes = () => {
  const { width } = useWindowDimensions();

  return (
    <BrowserRouter>
      <Switch>
        <LoadingRoute path='/' exact>
          <LoginHome />
        </LoadingRoute>
        {width < 500 && (
          <LoadingRoute path='/signin'>
            {' '}
            <SignIn />{' '}
          </LoadingRoute>
        )}
        <LoadingRoute path='/signup' exact>
          <SignUp />
        </LoadingRoute>
        <LoadingRoute path='/signup/empresa'>
          <SignUpEmpresa />
        </LoadingRoute>

        <PrivateRoute path='/dashboard' exact>
          <DashboardHome />
        </PrivateRoute>
        <PrivateRoute path='/finances'>
          <Finances />
        </PrivateRoute>
        <PrivateRoute path='/ingredients'>
          <Ingredients />
        </PrivateRoute>
        <PrivateRoute path='/recipes' exact>
          <RecipesTable />
        </PrivateRoute>
        <PrivateRoute path='/recipes/:id'>
          <RecipeDetails />
        </PrivateRoute>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
