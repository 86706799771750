import { Login } from '../model/Login.entity';
import { User } from '../model/User.entity';
import { auth } from './precin_api';

export const Auth = {
  async signin(body: Login) {
    try {
      const { data }: { data: User } = await auth.post<User>('/login', { email: body.email, password: body.password });

      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async signup(body: User) {
    try {
      const { data } = await auth.post('/register', body);
      return User.buildUser(data);
    } catch (err) {
      const errorMsgs: string[] = err.response.data.message;
      console.log(errorMsgs.join(','));
      throw new Error(errorMsgs.join(','));
    }
  },

  async signout() {
    try {
      await auth.post('/logout', undefined);
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async authenticate(): Promise<User> {
    try {
      const { data } = await auth.get<User>('/');
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async refresh(): Promise<User> {
    try {
      const { data } = await auth.get<User>('/refresh');
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },
};
