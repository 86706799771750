import * as Yup from 'yup';
import useYup from '../hooks/useYup';

export class Login {
  showPassword: boolean = false;
  email: string = '';
  password: string = '';
}

export const useLoginValidationSchema = () => {
  const { email, password } = useYup();

  return Yup.object({
    email,
    password,
  });
}
