import { LockOutlined, MailOutlineOutlined, PersonOutlineOutlined } from '@material-ui/icons';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import ErrorMessage from '../../../errors/ErrorMessage';
import useLabel from '../../../hooks/useLabel';
import { SignUp, useSignUpValidationSchema } from '../../../model/Signup.entity';
import { User } from '../../../model/User.entity';
import InputContainer from '../../containers/InputContainer/InputContainer';
import { CustomButton } from '../../core/Buttons/CustomButton';
import Form from '../../core/Form/Form';
import Input from '../../core/Input/Input';
import TogglePasswordIcon from '../../core/TogglePasswordIcon';

const SignUpForm = () => {
  const { location, push } = useHistory();
  const { getLabel } = useLabel();
  const { state } = location as { state: User };
  const { values, handleChange, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: SignUp.SignUpFromUser(state),
    validationSchema: useSignUpValidationSchema(),
    onSubmit: values => push('/signup/empresa', { ...values }),
  });
  const { firstName, lastName, email, password, cpf, confirmEmail, confirmPassword, showPassword, showConfirmPassword } = values;

  const togglePassword = (toggle: boolean, attribute: string) => <TogglePasswordIcon attribute={attribute} toggle={toggle} setValue={setFieldValue} />;

  return (
    <Form onSubmit={handleSubmit}>
      <InputContainer icon={<PersonOutlineOutlined color='disabled' fontSize='inherit' />}>
        <Input label={getLabel('firstName')} type='text' id='firstName' name='firstName' value={firstName} onChange={handleChange} />
        {touched.firstName && errors.firstName && <ErrorMessage errorMessage={errors.firstName} />}

        <Input label={getLabel('lastName')} type='text' id='lastName' name='lastName' value={lastName} onChange={handleChange} />
        {touched.lastName && errors.lastName && <ErrorMessage errorMessage={errors.lastName} />}

        <Input label='CPF' type='text' id='cpf' name='cpf' value={cpf} onChange={handleChange} />
        {touched.cpf && errors.cpf && <ErrorMessage errorMessage={errors.cpf} />}
      </InputContainer>

      <InputContainer icon={<MailOutlineOutlined color='disabled' fontSize='inherit' />}>
        <Input label='E-mail' type='email' name='email' id='email' value={email} onChange={handleChange} />
        {touched.email && errors.email && <ErrorMessage errorMessage={errors.email} />}
        <Input label={getLabel('confirmEmail')} type='email' name='confirmEmail' id='confirmEmail' value={confirmEmail} onChange={handleChange} />
        {touched.confirmEmail && errors.confirmEmail && <ErrorMessage errorMessage={errors.confirmEmail} />}
      </InputContainer>

      <InputContainer icon={<LockOutlined color='disabled' fontSize='inherit' />}>
        <Input
          label={getLabel('password')}
          id='password'
          name='password'
          value={password}
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          endIcon={togglePassword(showPassword, 'showPassword')}
        />
        {touched.password && errors.password && <ErrorMessage errorMessage={errors.password} />}

        <Input
          label={getLabel('confirmPassword')}
          id='confirmPassword'
          name='confirmPassword'
          value={confirmPassword}
          type={showConfirmPassword ? 'text' : 'password'}
          onChange={handleChange}
          endIcon={togglePassword(showConfirmPassword, 'showConfirmPassword')}
        />
        {touched.confirmPassword && errors.confirmPassword && <ErrorMessage errorMessage={errors.confirmPassword} />}
      </InputContainer>

      <CustomButton type='submit' variant='contained' color='secondary' size='large' fullWidth>
        {getLabel('createAccount')}
      </CustomButton>
    </Form>
  );
};

export default SignUpForm;
