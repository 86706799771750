import { createMuiTheme } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { typography } from './theme';
const darkPalette: PaletteOptions = {
  type: 'dark',
  primary: {
    main: '#FFADAD',
  },
  secondary: {
    main: '#FDFFB6',
  },
  error: {
    main: '#e63946',
  },
  grey: {
    '100': '#464353',
  },
  text: {
    primary: '#FFADAD',
    secondary: '#fafafa',
    hint: '#5F5F5F',
  },
  background: {
    default: '#02001c',
  },
};

const whitePalette: PaletteOptions = {
  ...darkPalette,
  type: 'light',
  background: {
    default: '#fdfdfd',
  },
};

const loginThemeConfig = (prefersDarkMode: boolean) =>
  createMuiTheme({
    palette: prefersDarkMode ? darkPalette : whitePalette,
    typography,
  });

export default loginThemeConfig;
