
export interface ConfirmDialogState<Type extends HasId> {
  data: Type;
  isOpen: boolean;
}

interface HasId {
  id: string | number;
}

function useMultipleDialogs<Type extends HasId>() {
  const getDialog = (confirmDialogs: ConfirmDialogState<Type>[], object: Type): boolean => {
    const dialog = confirmDialogs.find(dialog => dialog.data.id === object.id);
    if (!dialog)
      return false;
    return dialog.isOpen;
  };

  const toggleDialog = (id: string, dialogs: ConfirmDialogState<Type>[], setFunction: (value: React.SetStateAction<any>) => void) =>
  (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    const updatedDialogs = dialogs.map(dialog => (dialog.data.id === id ? { ...dialog, isOpen: !dialog.isOpen } : dialog));
    setFunction(updatedDialogs);
  };

  return { getDialog, toggleDialog };
}

export default useMultipleDialogs;