import * as Yup from 'yup';
import useYup from '../hooks/useYup';
import { BusinessExpense } from './BusinessExpense.entity';
import { BusinessPreference } from './BusinessPreference.entity';

export class Business {
  id: string = '';
  businessName?: string = '';
  cnpj: string = '';
  logoName?: string = '';
  logoFile?: string = '';
  logoContentType?: string = '';
  active: boolean = true;
  businessExpenses?: BusinessExpense[];
  businessCostPerMinute: number = 0;
  businessPreference?: BusinessPreference;
}

export const useBusinessValidationSchema = () => {
  const { name: businessName, cnpj } = useYup();

  return Yup.object({
    businessName,
    cnpj,
  });
}