import * as Yup from 'yup';
import useYup from '../hooks/useYup';
import { Business } from "./Business.entity";
import { PaymentMachine } from "./PaymentMachine.entity";

export class BusinessPaymentMachine {
  id: string = '';
  business: Business = new Business();
  paymentMachine = new PaymentMachine();
  debitFee: number = 0;
  creditFee: number = 0;
  main: boolean = false;

  static from(bPaymentMachine: BusinessPaymentMachine): BusinessPaymentMachine {
    const newInstance = new BusinessPaymentMachine();
    newInstance.id = bPaymentMachine.id;
    newInstance.business = bPaymentMachine.business;
    newInstance.paymentMachine = bPaymentMachine.paymentMachine;
    newInstance.debitFee = +bPaymentMachine.debitFee; 
    newInstance.creditFee = +bPaymentMachine.creditFee;
    newInstance.main = bPaymentMachine.main;
    return newInstance;
  }

  getHighestFee(): number {
    return this.debitFee > this.creditFee ? this.debitFee : this.creditFee;
  }
}

export const useCreateBusinessPaymentMachineValidationSchema = () => {
  const {percentualValue} = useYup();

  return Yup.object({
    debitFee: percentualValue,
    creditFee: percentualValue
  });
}