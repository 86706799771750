import { useEffect } from 'react';
import { Actions, useAppContext } from '../context/AppContext';
import { User } from '../model/User.entity';
import { Auth } from '../services/auth.service';
import useAppLoading from './useAppLoading';

const useAuth = () => {
  const { state, dispatch } = useAppContext();
  const { Loading, setAppLoading } = useAppLoading();

  const AuthEffect = () => {
    useEffect(() => {
      setAppLoading(true);

      const setIsAuthenticated = (value: boolean) => {
        dispatch({ type: Actions.SET_IS_AUTHENTICATED, payload: { value } });
      };

      function setUser(user: User): void {
        dispatch({ type: Actions.SET_USER, payload: { user } });
        setIsAuthenticated(true);
        setAppLoading(false);
      }

      if (state.user.id && state.user.id.length > 0) {
        setIsAuthenticated(true);
        setAppLoading(false);
        return;
      }

      (async () => {
        try{
          const user = await Auth.authenticate();
          if(user){
            setUser(user);
          }
        } catch (err) {
          try {
            const user = await Auth.refresh();
            setUser(user);
          } catch (errRefresh){
            setAppLoading(false);
            setIsAuthenticated(false);
          }
        }
      })();
      
    }, []);
  };

  return { AuthEffect, Loading };
};

export default useAuth;