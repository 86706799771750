/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, IconButton, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Create, DeleteOutlined, EditOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { BluePaper } from '../../components/core/Paper/CustomPaper';
import { CellColorInherit, CustomTable, CustomTableRow, LeftTableCell, MiddleTableCell, RightTableCell } from '../../components/core/Table/CustomTable';
import { BlackTypography } from '../../components/core/Typography/CustomTypography';
import { ConfirmDialog } from '../../components/dialogs/ConfirmDialog';
import NewProductDialog from '../../components/dialogs/NewProductDialog';
import { useAppContext } from '../../context/AppContext';
import useAlert from '../../hooks/useAlert';
import useFormatter from '../../hooks/useFormatter';
import useMultipleDialogs, { ConfirmDialogState } from '../../hooks/useMultipleDialogs';
import { BusinessProduct } from '../../model/BusinessProduct.entity';
import { BusinessService } from '../../services/business.service';

interface ProductsTableProps {}

const ProductsTable: React.FC<ProductsTableProps> = () => {
  const [products, setProducts] = useState<BusinessProduct[]>([]);
  const { currencyFormatter } = useFormatter();
  const { state } = useAppContext();
  const { user } = state;
  const [isLoading, setIsLoading] = useState(true);
  const { errorAlert, successAlert } = useAlert();
  const {getDialog, toggleDialog} = useMultipleDialogs<BusinessProduct>();
  const [confirmDialogs, setConfirmDialogs] = useState<ConfirmDialogState<BusinessProduct>[]>([]);
  const [openNewProductDialog, setOpenNewProductDialog] = useState(false);
  const [editProductDialogs, setEditProductDialogs] = useState<ConfirmDialogState<BusinessProduct>[]>([]);
  const [loadProductsCounter, setLoadProductsCounter] = useState<number>(0);
  // Load products for the current business
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (!user.businesses || products.length > 0 || loadProductsCounter > 0) {
        setIsLoading(false);
        return;
      }
      try {
        const products = await BusinessService.getBusinessProducts(user.businesses[0].id);
        setLoadProductsCounter(loadProductsCounter + 1);
        const productDialogs = products.map(businessProduct => ({ data: businessProduct, isOpen: false } as ConfirmDialogState<BusinessProduct>));
        setProducts(products);
        setConfirmDialogs(productDialogs);
        setEditProductDialogs(productDialogs);
      } catch (err) {
        errorAlert(err.message ?? err);
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {
      setIsLoading(false);
    };
  });

  const handleDelete = (businessProductId: string) => async (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!user.businesses) {
      return;
    }

    try {
      setIsLoading(true);

      await BusinessService.deleteBusinessProduct(user.businesses[0].id, businessProductId);
      
      const updatedProducts = products.filter(businessProduct => businessProduct.id !== businessProductId);
      setProducts(updatedProducts);

      successAlert();
    } catch (err) {
      errorAlert(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <TableContainer component={BluePaper}>
      <CustomTable size='medium' aria-label='Costs table'>
        <TableHead style={{ color: '#000' }}>
          <TableRow>
            <CellColorInherit bold align='center'>
              Produto
            </CellColorInherit>
            <CellColorInherit bold align='center'>
              Marca
            </CellColorInherit>
            <CellColorInherit bold align='center'>
              Qtd Embalagem
            </CellColorInherit>
            <CellColorInherit bold align='center'>
              Unidade Medida
            </CellColorInherit>
            <CellColorInherit bold align='center'>
              Preço Embalagem
            </CellColorInherit>
            <CellColorInherit bold align='center'>
              Ações
            </CellColorInherit>
          </TableRow>
        </TableHead>
        <TableBody style={{ color: '#000' }}>
        {products.length <= 0 && (
            <CustomTableRow>
              <LeftTableCell colSpan={4} align='center'>
                Nenhum produto criado
              </LeftTableCell>
              <RightTableCell colSpan={2} align='center'>
                <Button startIcon={<Create color='action' />} onClick={() => setOpenNewProductDialog(true)}>
                  <BlackTypography variant='body1'>Criar</BlackTypography>
                </Button>
              </RightTableCell>
            </CustomTableRow>
          )}
          {products.map(businessProduct => (
            <CustomTableRow key={businessProduct.id}>
              <LeftTableCell align='center'>{businessProduct.customProductName ?? businessProduct.product.name}</LeftTableCell>
              <MiddleTableCell align='center'>{businessProduct.product.brand.name}</MiddleTableCell>
              <MiddleTableCell align='center'>{businessProduct.product.quantity}</MiddleTableCell>
              <MiddleTableCell align='center'>{businessProduct.product.unit}</MiddleTableCell>
              <MiddleTableCell align='center'>{currencyFormatter(businessProduct.customPrice)}</MiddleTableCell>
              <RightTableCell align='center'>
                <IconButton onClick={toggleDialog(businessProduct.id, editProductDialogs, setEditProductDialogs)} color={'inherit'}>
                  <EditOutlined />
                </IconButton>
                <IconButton color={'inherit'} onClick={toggleDialog(businessProduct.id, confirmDialogs, setConfirmDialogs)} value={businessProduct.id}>
                  <DeleteOutlined />
                </IconButton>
              </RightTableCell>
              <NewProductDialog data={businessProduct} open={getDialog(editProductDialogs, businessProduct)} handleClose={toggleDialog(businessProduct.id, editProductDialogs, setEditProductDialogs)} />
              <ConfirmDialog
                value={businessProduct.customProductName ?? businessProduct.product.name}
                handleConfirm={handleDelete(businessProduct.id)}
                open={getDialog(confirmDialogs, businessProduct)}
                handleClose={toggleDialog(businessProduct.id, confirmDialogs, setConfirmDialogs)}  
              />
            </CustomTableRow>
          ))}
        </TableBody>
      </CustomTable>
      <NewProductDialog open={openNewProductDialog} handleClose={() => setOpenNewProductDialog(false)} />
    </TableContainer>
  );
};

export default ProductsTable;


