import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Actions, useAppContext } from '../../context/AppContext';
import ErrorMessage from '../../errors/ErrorMessage';
import useAlert from '../../hooks/useAlert';
import useAppLoading from '../../hooks/useAppLoading';
import { CustomDialogProps } from "../../model/CustomDialogProps";
import { Salary, useSalaryValidationSchema } from '../../model/Salary.entity';
import { UserService } from '../../services/user.service';

const SalaryDialog: React.FC<CustomDialogProps<Salary>> = ({ data, open, handleClose }) => {
  const { state, dispatch } = useAppContext();
  const { errorAlert, successAlert } = useAlert();
  const { setAppLoading } = useAppLoading();
  const { handleSubmit, values, handleChange, setValues, errors, touched } = useFormik({
    initialValues: state.user.salary ?? new Salary(),
    onSubmit,
    validationSchema: useSalaryValidationSchema(),
  });
  const salaryDefined = !!state.user.salary;

  useEffect(() => {
    if (!state.user) return;
    if (salaryDefined) return;
    (async () => { 
      try {
        const salary = await UserService.getSalary(state.user)
          setValues(salary)
      } catch (err) {
        errorAlert(err.message ?? err);
      }
    })(); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValues, state.user, salaryDefined]);

  async function onSubmit(values: Salary) {
    try {
      setAppLoading(true);
      const salary = salaryDefined ? await UserService.updateSalary(state.user, values) : await UserService.createSalary(state.user, values);
      const user = state.user;
      user.salary = salary;
      dispatch({ type: Actions.SET_USER, payload: { user } });
      successAlert();
    } catch (err) {
      errorAlert(err);
    } finally {
      setAppLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Meu Salário</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>Defina o seu salário desejado</DialogContentText>
          <TextField autoFocus margin='dense' id='salaryValue' name='salaryValue' onChange={handleChange} label='Quanto quero ganhar?' type='number' fullWidth value={values.salaryValue} />
          {touched.salaryValue && errors.salaryValue && <ErrorMessage errorMessage={errors.salaryValue} />}

          <TextField margin='dense' id='businessDays' name='businessDays' onChange={handleChange} label='Quantos dias vou trabalhar por mês?' type='number' fullWidth value={values.businessDays} />
          {touched.businessDays && errors.businessDays && <ErrorMessage errorMessage={errors.businessDays} />}

          <TextField margin='dense' id='businessHours' name='businessHours' onChange={handleChange} label='Quantas horas vou trabalhar por dia?' type='number' fullWidth value={values.businessHours} />
          {touched.businessHours && errors.businessHours && <ErrorMessage errorMessage={errors.businessHours} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancelar
          </Button>

          <Button type='submit' variant='contained' color='primary'>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SalaryDialog;
