import { CircularProgress, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BluePaper } from '../../components/core/Paper/CustomPaper';
import { CellColorInherit, CustomTable, CustomTableRow, LeftTableCell, MiddleTableCell, RightTableCell } from '../../components/core/Table/CustomTable';
import { useAppContext } from '../../context/AppContext';
import useAlert from '../../hooks/useAlert';
import useFormatter from '../../hooks/useFormatter';
import { Recipe } from '../../model/Recipe.entity';
import { BusinessService } from '../../services/business.service';
import RecipesContainer from './RecipesContainer';

interface RecipesTableProps {}

const RecipesTable: React.FC<RecipesTableProps> = () => {
  const { state } = useAppContext();
  const { user } = state;
	const { currencyFormatter } = useFormatter();
  const { errorAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  const [recipes, setRecipes] = useState<Recipe[]>([])
	// Load recipes for the current business
	useEffect(() => {
		(async () => {
			setIsLoading(true);
			if (!user.businesses || recipes.length > 0) {
				setIsLoading(false);
				return;
			}
			try {
				const recipes = await BusinessService.getRecipes(user.businesses[0].id);
				// const productDialogs = recipes.map(businessProduct => ({ data: businessProduct, isOpen: false } as ConfirmDialogState<BusinessProduct>));
				setRecipes(recipes);
				// setConfirmDialogs(productDialogs);
				// setEditProductDialogs(productDialogs);
			} catch (err) {
				errorAlert(err.message ?? err);
			} finally {
				setIsLoading(false);
			}
		})();

		return () => {
			setIsLoading(false);
		};
	});

	return isLoading ? (<CircularProgress />) : (
		<RecipesContainer title={'Lista de Receitas'}>
			<TableContainer component={BluePaper}>
				<CustomTable size='medium' aria-label='Costs table'>
					<TableHead style={{ color: '#000' }}>
						<TableRow>
							<CellColorInherit bold align='center'>
								Receita
							</CellColorInherit>
							<CellColorInherit bold align='center'>
								Preço Unitário
							</CellColorInherit>
							<CellColorInherit bold align='center'>
								Valor de Venda Sugerido
							</CellColorInherit>
							<CellColorInherit bold align='center'>
								Valor de Venda Cartão
							</CellColorInherit>
							<CellColorInherit bold align='center'>
								Valor de Venda App
							</CellColorInherit>
						</TableRow>
					</TableHead>
					<TableBody style={{ color: '#000' }}>
						{recipes.map(recipe => (
							<CustomTableRow key={recipe.id}>
								<LeftTableCell bold align='center'>
									<Link to={`/recipes/${recipe.id}`}>{recipe.name}</Link>
								</LeftTableCell>
								<MiddleTableCell align='center'>{currencyFormatter(recipe.currentUnitPrice)}</MiddleTableCell>
								<MiddleTableCell align='center'>{currencyFormatter(recipe.suggestedUnitPrice)}</MiddleTableCell>
								<MiddleTableCell align='center'>{currencyFormatter(recipe.suggestedUnitCardPrice)}</MiddleTableCell>
								<RightTableCell align='center'>{currencyFormatter(recipe.suggestedUnitFoodAppPrice)}</RightTableCell>
							</CustomTableRow>
						))}
					</TableBody>
				</CustomTable>
			</TableContainer>
		</RecipesContainer>
	);
};

export default RecipesTable;
