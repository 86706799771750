import * as Yup from 'yup';
import useYup from '../hooks/useYup';
import { Business } from './Business.entity';
import { Product, useProductValidationSchema } from './Product.entity';

export enum BusinessProductCategory {
  INGREDIENTE = 'ingrediente',
  EMBALAGEM = 'embalagem',
}

export class BusinessProduct {
  id: string = '';
  customPrice: number = 0;
  customProductName?: string;
  category: BusinessProductCategory = BusinessProductCategory.INGREDIENTE;
  product: Product = new Product();
  business: Business = new Business();

  static from(businessProduct: BusinessProduct) {
    const newInstance = new BusinessProduct();
    newInstance.id = businessProduct.id;
    newInstance.customPrice = businessProduct.customPrice;
    newInstance.customProductName = businessProduct.customProductName;
    newInstance.category = businessProduct.category;
    newInstance.product = businessProduct.product;
    newInstance.business = businessProduct.business;
    newInstance.parseNumbers();
    return newInstance;
  }

  parseNumbers(){
    this.customPrice = +this.customPrice;
    this.product.estimatedPrice = this.product.estimatedPrice ? +this.product.estimatedPrice : undefined;
    this.product.quantity = +this.product.quantity;
  }
}

export const useCreateBusinessProductDtoValidationSchema = () => {
  const {financialValue, category} = useYup();
  const productSchema = useProductValidationSchema();

  return Yup.object({
    customPrice: financialValue,
    category, 
    product: productSchema
  });
}
