import { createStyles, styled, Table, TableCell, TableCellProps, TableRow, Theme, withStyles } from '@material-ui/core';

export const CustomTable = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderCollapse: 'separate',
      borderSpacing: '0 0.5rem',
    },
  })
)(Table);

export const CustomTablePadded = styled(CustomTable)({
  padding: '0 2%',
});

export const CustomTableRow = styled(TableRow)({
  backgroundColor: 'transparent',
});
interface CellColorInheritProps {
  bold?: boolean;
}
export const CellColorInherit = styled(({ bold, ...other }: CellColorInheritProps & Omit<TableCellProps, keyof CellColorInheritProps>) => <TableCell {...other} />)({
  color: 'inherit',
  fontWeight: ({ bold }: CellColorInheritProps) => (bold ? 'bold' : 'normal'),
  border: 'none',
});

export const MiddleTableCell = styled(CellColorInherit)({
  borderRadius: 'none',
  backgroundColor: 'rgba(255, 255, 252,0.2)',
  verticalAlign: 'middle'
});

export const BgDarkTableCell = styled(MiddleTableCell)({
  backgroundColor: 'rgba(160, 196, 255, 0.6)',
});

export const LeftTableCell = styled(MiddleTableCell)({
  borderRadius: '10px 0 0 10px',
  padding: '0 2%',
});

export const RightTableCell = styled(MiddleTableCell)({
  borderRadius: '0 10px 10px 0',
});
