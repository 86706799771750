export enum Locale {
  PT_BR = 'pt-BR',
  EN_US = 'en-US',
}

export const CURRENCY = {
  [Locale.PT_BR]: 'BRL',
  [Locale.EN_US]: 'USD',
};

export type LocaleMessage = { [key in Locale]: string };
