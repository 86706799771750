import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import LoginContainer from '../../components/containers/LoginContainer/LoginContainer';
import SignUpForm from '../../components/forms/SignUpForm/SignUpForm';
import { CustomLink } from '../SignIn/SignIn';

const SignUp = () => {
  return (
    <LoginContainer>
      <CustomLink to='/'>
        <ArrowBack fontSize='inherit' color='inherit' />
      </CustomLink>

      <SignUpForm />
    </LoginContainer>
  );
};

export default SignUp;
