import { styled, withTheme } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import LoginContainer from '../../components/containers/LoginContainer/LoginContainer';
import LoginForm from '../../components/forms/LoginForm/LoginForm';

export const CustomLink = styled(withTheme(Link))(props => ({
  position: 'absolute',
  top: 5,
  left: 5,
  fontSize: props.theme.typography.h3.fontSize,
  color: props.theme.palette.common.black,
}));

const SignIn = () => {
  return (
    <LoginContainer>
      <CustomLink to='/'>
        <ArrowBack fontSize={'inherit'} color='inherit' />
      </CustomLink>

      <LoginForm />
    </LoginContainer>
  );
};

export default SignIn;
