import { FoodApp } from "../model/FoodApp.entity";
import { foodApps } from "./precin_api";

export const FoodAppService = {
  async getFoodApps(): Promise<FoodApp[]> {
    try {
      const { data } = await foodApps.get<FoodApp[]>('');
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },
}