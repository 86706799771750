import { ThemeProvider, useMediaQuery } from '@material-ui/core';
import React, { useMemo, useReducer } from 'react';
import './assets/styles/global.css';
import { AppContext, INITIAL_STATE, reducer } from './context/AppContext';
import { ColorTheme } from './model/ColorTheme.entity';
import Routes from './routes';
import themeConfig from './theme/theme';
import { Locale } from './utils/locale';

function App() {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  if (state.user.preference.colorTheme !== ColorTheme.SYSTEM) prefersDarkMode = state.user.preference.colorTheme === 'dark';

  const getThemeConfig = themeConfig(prefersDarkMode, state.locale === Locale.PT_BR);

  const theme = useMemo(() => getThemeConfig, [getThemeConfig]);

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={{ state, dispatch }}>
        <Routes />
      </AppContext.Provider>
    </ThemeProvider>
  );
}

export default App;
