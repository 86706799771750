/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Switch, TextField, Tooltip } from "@material-ui/core";
import { HelpOutline } from "@material-ui/icons";
import { useFormik } from "formik";
import { memo, useEffect, useState } from "react";
import { Actions, useAppContext } from "../../context/AppContext";
import ErrorMessage from '../../errors/ErrorMessage';
import useAlert from "../../hooks/useAlert";
import useAppLoading from "../../hooks/useAppLoading";
import useYup from "../../hooks/useYup";
import { Business } from "../../model/Business.entity";
import { BusinessFoodApp, useCreateBusinessFoodAppValidationSchema } from "../../model/BusinessFoodApp.entity";
import { CustomDialogProps } from "../../model/CustomDialogProps";
import { FoodApp } from "../../model/FoodApp.entity";
import { BusinessService } from "../../services/business.service";
import { FoodAppService } from "../../services/foodApp.service";
import { AsyncAutocomplete } from "../core/Autocomplete/AsyncAutocomplete";

const NewFoodAppDialog: React.FC<CustomDialogProps<BusinessFoodApp>> = ({ data, open, handleClose }) => {
  const { values, handleChange, handleSubmit, setFieldValue, touched, errors } = useFormik({
    initialValues: data ?? new BusinessFoodApp(),
    onSubmit,
    validationSchema: useCreateBusinessFoodAppValidationSchema(),
  });
  const { state, dispatch } = useAppContext();
  const { user } = state;
  const { setAppLoading } = useAppLoading();
  const { successAlert, errorAlert } = useAlert();
  const {validateName} = useYup();

  const [foodApps, setFoodApps] = useState<FoodApp[]>([]);
  const [foodAppNameInputValue, setFoodAppNameInputValue] = useState('');

  const loadingFoodApps = foodApps.length === 0;

  // Load all payment machines
  useEffect(() => {
    let active = true;

    if (!loadingFoodApps || foodApps.length > 0) {
      return;
    }

    (async () => {
      try {
      const foodApps = await FoodAppService.getFoodApps();

        if (active) {
          setFoodApps(foodApps);
        }
      } catch (err) {
        errorAlert(err.message ?? err);
      }
    })();

    return () => {
      active = false;
    };
  }, [loadingFoodApps]);

  async function onSubmit(values: BusinessFoodApp) {
    try {
      if (user.businesses && (!values.business || !values.business.id)) {
        values.business = new Business();
        values.business.id = user.businesses[0].id;
      }
      setAppLoading(true);

      if(foodAppNameInputValue) {
        values.foodApp.name = foodAppNameInputValue;
        values.foodApp.id = undefined;
      }
      await validateName(values.foodApp.name, 'firstName');

      const businessFoodApp = await BusinessService.createOrUpdateBusinessFoodApp(values);
      if(values.main){
        dispatch({ type: Actions.CHANGE_MAIN_BUSINESS_FOOD_APP, payload: { businessFoodApp }})
      }

      successAlert();
    } catch (err) {
      errorAlert(err.message ?? err);
    } finally {
      setAppLoading(false);
    }
  }
  
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='new-food-app-dialog'>
      <DialogTitle id='new-food-app-dialog'>Novo Aplicativo de Entrega</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>Crie um novo aplicativo de entrega para a sua empresa</DialogContentText>
              
          <AsyncAutocomplete
            freeSolo={true}
            options={foodApps}
            getOptionSelected={(option: FoodApp, value: string) => option.name === value}
            loading={loadingFoodApps}
            setOptions={setFoodApps}
            value={values.foodApp}
            label={'Nome do Aplicativo'}
            optionAttribute={'name'}
            id='foodApp'
            onInputChange={(_e, value) => setFoodAppNameInputValue(value)}
            onChange={(_e, value: FoodApp) => {
              setFieldValue('foodApp.name', value?.name ?? '');
            }}
          />

          <TextField margin='dense' id='fee' label='Taxa' type='number' name='fee' fullWidth onChange={handleChange} value={values.fee} />
          {touched.fee && errors.fee && <ErrorMessage errorMessage={errors.fee} />}

          <Box display='flex' alignItems='center'>
          <FormControlLabel control={<Switch checked={values.main} id='main' onChange={handleChange} name="main" />} label="Principal?"/>
          <Tooltip placement='right' title="Ao definir o app como principal, ele será automáticamente escolhido ao criar novas receitas.">
                  <HelpOutline fontSize="small"  />
          </Tooltip>
          </Box>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancelar
          </Button>
          <Button type='submit' variant='contained' color='primary'>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default memo(NewFoodAppDialog);