import { Color } from '@material-ui/lab';
import { Actions, useAppContext } from '../context/AppContext';
import { Alert } from '../model/Alert.entity';

const useAlert = () => {
  const { dispatch } = useAppContext();

  
  const errorAlert = (error: Error) => {
    dispatch({ type: Actions.SET_ALERT, payload: { alert: Alert.from({ open: true, message: error.message ?? error, severity: 'error' }) } });
  };

  const successAlert = (message?: string) => {
    dispatch({ type: Actions.SET_ALERT, payload: { alert: Alert.from({ open: true, message }) } });
  };

  const triggerAlert = (message?: string, severity?: Color) => {
    dispatch({ type: Actions.SET_ALERT, payload: { alert: Alert.from({ open: true, message, severity }) } });
  };

  return { errorAlert, successAlert, triggerAlert };
};

export default useAlert;