import * as Yup from 'yup';
import useYup from '../hooks/useYup';
import { Business } from "./Business.entity";
import { FoodApp } from './FoodApp.entity';

export class BusinessFoodApp {
  id: string = '';
  business: Business = new Business();
  foodApp = new FoodApp();
  fee: number = 0;
  main: boolean = false;

  static from(bFoodApp: BusinessFoodApp): BusinessFoodApp {
    const newInstance = new BusinessFoodApp();
    newInstance.id = bFoodApp.id;
    newInstance.business = bFoodApp.business;
    newInstance.foodApp = bFoodApp.foodApp;
    newInstance.fee = +bFoodApp.fee;
    newInstance.main = bFoodApp.main;
    return newInstance;
  }
}

export const useCreateBusinessFoodAppValidationSchema = () => {
  const {percentualValue} = useYup();

  return Yup.object({
    fee: percentualValue,
  });
}