import { Brand } from '../model/Brand.entity';
import { brands } from './precin_api';

export const BrandService = {
  async getBrands(): Promise<Brand[]> {
    try {
      const { data } = await brands.get<Brand[]>('');
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },
};
