import { Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { LightBluePaperFit } from '../../components/core/Paper/CustomPaper';
import { BgDarkTableCell, CellColorInherit, CustomTablePadded, CustomTableRow, LeftTableCell, MiddleTableCell, RightTableCell } from '../../components/core/Table/CustomTable';
import useFormatter from '../../hooks/useFormatter';
import { Recipe } from '../../model/Recipe.entity';

interface SellTableProps {
  recipe: Recipe;
}

const SellTable: React.FC<SellTableProps> = ({ recipe }) => {
  const { currencyFormatter } = useFormatter();
  const rows = [
    { title: 'Dinheiro', recipeValue: currencyFormatter(recipe.suggestedUnitPrice * recipe.yield), unitValue: currencyFormatter(recipe.suggestedUnitPrice) },
    { title: 'Cartão', recipeValue: currencyFormatter(recipe.suggestedUnitCardPrice * recipe.yield), unitValue: currencyFormatter(recipe.suggestedUnitCardPrice) },
    { title: 'App', recipeValue: currencyFormatter(recipe.suggestedUnitFoodAppPrice * recipe.yield), unitValue: currencyFormatter(recipe.suggestedUnitCardPrice) },
  ];
  return (
    <TableContainer component={LightBluePaperFit}>
      <Table size='small' aria-label='Sell table'>
        <TableHead style={{ color: '#000' }}>
          <TableRow>
            <BgDarkTableCell colSpan={3} bold align='center'>
              Venda
            </BgDarkTableCell>
          </TableRow>
        </TableHead>
        <CustomTablePadded size='small' aria-label='Sell table body'>
          <TableHead style={{ color: '#000' }}>
            <TableRow>
              <CellColorInherit />
              <CellColorInherit bold align='center'>
                Valor Receita
              </CellColorInherit>
              <CellColorInherit bold align='center'>
                Valor Unitário
              </CellColorInherit>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: '#000' }}>
            {rows.map(row => (
              <CustomTableRow key={row.title}>
                <LeftTableCell bold align='center'>
                  {row.title}
                </LeftTableCell>
                <MiddleTableCell align='center'>{row.recipeValue}</MiddleTableCell>
                <RightTableCell align='center'>{row.unitValue}</RightTableCell>
              </CustomTableRow>
            ))}
          </TableBody>
        </CustomTablePadded>
      </Table>
    </TableContainer>
  );
};

export default SellTable;
