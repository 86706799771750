import { enUS, ptBR } from '@material-ui/core/locale';
import { createTheme } from '@material-ui/core/styles';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';

declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    drawer: string;
  }

  interface TypeText {
    drawerMenu: string;
  }
}

const darkPalette: PaletteOptions = {
  type: 'dark',
  primary: {
    main: '#FFADAD',
  },
  secondary: {
    main: '#FDFFB6',
  },
  common: {
    black: '#30374e',
  },
  info: {
    main: '#A0C4FF',
    light: 'rgba(204, 218, 242, 0.5)',
  },
  grey: {
    '100': '#5F5F5F',
  },
  text: {
    primary: '#FFADAD',
    secondary: '#F2F2F2',
    hint: '#AD8089',
    drawerMenu: '#5F5F5F',
  },
  background: {
    drawer: '#A0C4FF',
  },
};

const whitePalette: PaletteOptions = {
  ...darkPalette,
  type: 'light',
  background: {
    default: '#fdfdfd',
  },
  text: {
    primary: '#A0C4FF',
    secondary: '#5F5F5F',
    hint: '#AD8089',
    drawerMenu: '#5F5F5F',
  },
};

export const typography: TypographyOptions = {
  fontFamily: 'Poppins, Archivo',
  fontSize: 12,
  htmlFontSize: 14,
  h1: {
    fontWeight: 'bold',
    fontSize: '3rem',
  },
  h2: {
    fontWeight: 'bold',
    fontSize: '2.5rem',
  },
  h3: {
    fontWeight: 'bold',
    fontSize: '2rem',
  },
  h4: {
    fontSize: '1.5rem',
  },
  h5: {
    fontSize: '1.25rem',
  },
  h6: {
    fontSize: '1.1rem',
  },
  button: {
    fontSize: '1.2rem',
  },
};

const themeConfig = (prefersDarkMode: boolean, isLocaleBR: boolean) =>
  createTheme(
    {
      palette: prefersDarkMode ? darkPalette : whitePalette,
      typography,
      shape: {
        borderRadius: 20,
      },
    },
    isLocaleBR ? ptBR : enUS
  );

export default themeConfig;
