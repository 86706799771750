import { styled, Theme, Typography, TypographyProps, withStyles } from '@material-ui/core';
import React from 'react';
import { IFontWeight } from '../../../utils/types';

export const GreyTypography = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.grey[100],
  },
}))(Typography);

export const BlackTypography = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.black
  },
}))(Typography);

export const BlackTypographyDymamicWeight = styled(({ color, ...other }: IFontWeight & Omit<TypographyProps, keyof IFontWeight>) => <BlackTypography {...other} />)({
  fontWeight: (props: IFontWeight) => props.fontWeight ?? 'normal',
});