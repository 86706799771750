import { LoadingBackrop as Loading } from '../components/core/Loading/LoadingBackdrop';
import { Actions, useAppContext } from '../context/AppContext';

const useAppLoading = () => {
  const { dispatch } = useAppContext();

  const setAppLoading = (value: boolean) => dispatch({ type: Actions.SET_APP_LOADING, payload: { value } });

  return { setAppLoading, Loading };
};

export default useAppLoading;
