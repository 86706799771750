import { createStyles, ListItemIcon, makeStyles, MenuItem, SvgIconTypeMap, Theme, Typography } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import React from 'react';

interface IconMenuItemProps {
  text: string;
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  value?: string;
  selected?: boolean;
  color?: string;
}

const useStyles = (props: IconMenuItemProps) =>
  makeStyles((theme: Theme) =>
    createStyles({
      userMenuItem: {
        fontSize: 'inherit',
        fontWeight: 'inherit',
        color: props.color ?? theme.palette.text.drawerMenu,
      },
      textColor: {
        color: props.color ?? theme.palette.text.drawerMenu,
      },
    })
  );

const IconMenuItem: React.FC<IconMenuItemProps> = props => {
  const classes = useStyles(props)();
  const Icon = props.icon;

  return (
    <MenuItem selected={props.selected} className={classes.userMenuItem} onClick={props.onClick} value={props.value}>
      <ListItemIcon classes={{ root: classes.userMenuItem }}>
        <Icon fontSize={'medium'} color='inherit' />
      </ListItemIcon>
      <Typography variant={'inherit'} className={classes.textColor}>
        {props.text}
      </Typography>
    </MenuItem>
  );
};

export default IconMenuItem;
