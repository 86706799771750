import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { LightBluePaperFit } from '../../components/core/Paper/CustomPaper';
import { BgDarkTableCell, CellColorInherit, CustomTablePadded, CustomTableRow, LeftTableCell, MiddleTableCell, RightTableCell } from '../../components/core/Table/CustomTable';
import { Recipe } from '../../model/Recipe.entity';

interface RecipeInstructionsProps {
  recipe: Recipe;
}

const headers = ['Instrução', 'Temp °C', 'Tempo'];

const RecipeInstructions: React.FC<RecipeInstructionsProps> = ({ recipe }) => {
  return (
    <Box paddingY='2%'>
      <TableContainer component={LightBluePaperFit}>
        <Table size='medium' aria-label='Instructions table'>
          <TableHead style={{ color: '#000' }}>
            <TableRow>
              <BgDarkTableCell colSpan={2} bold align='center'>
                Preparo
              </BgDarkTableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: '#000' }}>
            <CustomTablePadded size='medium' aria-label='Sell table body'>
              <TableHead style={{ color: '#000' }}>
                <TableRow>
                  {headers.map(header => (
                    <CellColorInherit key={header} bold align='center'>
                      {header}
                    </CellColorInherit>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody style={{ color: '#000' }}>
                {recipe &&
                  recipe.instructions &&
                  recipe.instructions.map(instruction => (
                    <CustomTableRow key={instruction.id}>
                      <LeftTableCell align='center'>{instruction.instruction}</LeftTableCell>
                      <MiddleTableCell align='center'>{instruction.temperature}</MiddleTableCell>
                      <RightTableCell align='center'>{instruction.duration}</RightTableCell>
                    </CustomTableRow>
                  ))}
              </TableBody>
            </CustomTablePadded>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RecipeInstructions;
