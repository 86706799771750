import { Paper, styled, withTheme } from '@material-ui/core';

export const CustomPaper = styled(Paper)({
  backgroundColor: 'transparent',
  color: '#000',
  boxShadow: 'none',
});

export const LightBluePaperFit = styled(withTheme(Paper))(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  boxShadow: theme.shadows[1],
}));

export const LightBluePaper = styled(withTheme(LightBluePaperFit))(({ theme }) => ({
  padding: `1% 2%`,
}));

export const BluePaper = styled(withTheme(LightBluePaper))(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
}));

export const WhitePaperLightOpacity = styled(withTheme(LightBluePaperFit))(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  opacity: 0.5,
}));
