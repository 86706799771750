import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      paddingLeft: '2%',
      color: theme.palette.text.secondary,
      fontSize: theme.typography.body1.fontSize,
    },
    roundedInput: {
      borderRadius: 50,
    },
    inputLabel: {
      marginLeft: '10%',
      marginTop: '1%',
      fontSize: theme.typography.body2.fontSize,
    },
    formControl: {
      margin: '1% 0',
    },
  })
);
