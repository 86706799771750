import { Box, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import { LightBluePaperFit } from '../../components/core/Paper/CustomPaper';
import { BgDarkTableCell, CellColorInherit, CustomTablePadded, CustomTableRow, LeftTableCell, MiddleTableCell, RightTableCell } from '../../components/core/Table/CustomTable';
import useFormatter from '../../hooks/useFormatter';
import { Recipe } from '../../model/Recipe.entity';

interface RecipeProductsTableProps {
  recipe: Recipe;
}


const RecipeProductsTable: React.FC<RecipeProductsTableProps> = ({ recipe }) => {
  const { currencyFormatter, percentageFormatter } = useFormatter();
  const headers = ['Produto', 'Marca', 'Qtd.Embalagem', 'Medida', 'Preço Embalagem', 'Qtd. Receita', 'Custo Un.', 'Custo Receita', 'Custo %'];

  return (
    <Box paddingY='2%'>
      <TableContainer component={LightBluePaperFit}>
        <Table size='medium' aria-label='Instructions table'>
          <TableHead style={{ color: '#000' }}>
            <TableRow>
              <BgDarkTableCell colSpan={2} bold align='center'>
                Insumos
              </BgDarkTableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ color: '#000' }}>
            <CustomTablePadded size='medium' aria-label='Sell table body'>
              <TableHead style={{ color: '#000' }}>
                <TableRow>
                  {headers.map(header => (
                    <CellColorInherit key={header} bold align='center'>
                      {header}
                    </CellColorInherit>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody style={{ color: '#000' }}>
                {recipe &&
                  recipe.recipeBusinessProduct &&
                  recipe.recipeBusinessProduct.map(({ id, businessProduct, recipeAmount }) => {
                    const unitCost = businessProduct.customPrice / businessProduct.product.quantity;
                    const recipeCost = unitCost * recipeAmount;
                    return (
                      <CustomTableRow key={id}>
                      <LeftTableCell align='center'>{businessProduct.product.name}</LeftTableCell>
                      <MiddleTableCell align='center'>{businessProduct.product.brand.name}</MiddleTableCell>
                      <MiddleTableCell align='center'>{businessProduct.product.quantity}</MiddleTableCell>
                      <MiddleTableCell align='center'>{businessProduct.product.unit}</MiddleTableCell>
                      <MiddleTableCell align='center'>{currencyFormatter(businessProduct.customPrice)}</MiddleTableCell>
                      <MiddleTableCell align='center'>{recipeAmount}</MiddleTableCell>
                      <MiddleTableCell align='center'>{currencyFormatter(unitCost)}</MiddleTableCell>
                      <MiddleTableCell align='center'>{currencyFormatter(recipeCost)}</MiddleTableCell>
                      <RightTableCell align='center'>{percentageFormatter(recipeCost / (recipe.suppliesUnitCost * recipe.yield))}</RightTableCell>
                    </CustomTableRow>
                    )
                  })}
              </TableBody>
            </CustomTablePadded>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RecipeProductsTable;
