import { Box } from '@material-ui/core';
import React from 'react';
import DashboardContainer from '../../components/containers/DashboardContainer/DashboardContainer';
import ProductsTable from './ProductsTable';

const Ingredients = () => {
  return (
    <DashboardContainer page={'ingredients'} title={'Lista de Ingredientes'}>
      <Box marginY='2%'>
        <ProductsTable />
      </Box>
    </DashboardContainer>
  );
};

export default Ingredients;
