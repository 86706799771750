import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const drawerWidth = 'auto';
const drawer = {
  marginTop: '10%',
  borderRadius: '0 40px',
  border: 'unset',
};
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'transparent',
      background: 'unset',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: 'unset',
      marginTop: '5px',
      padding: '0 5px',
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      color: theme.palette.text.drawerMenu,
    },
    drawerOpen: {
      ...drawer,
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxShadow: theme.shadows[5],
      backgroundColor: theme.palette.info.main,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 'unset',
        width: '100%',
        zIndex: 10000,
        marginTop: '25%',
      },
    },
    drawerClose: {
      ...drawer,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      boxShadow: theme.shadows[5],
      backgroundColor: theme.palette.info.main,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '25%',
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 1),
      color: theme.palette.text.drawerMenu,
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    logo: {
      maxWidth: '4rem',
      padding: '1% 0',
      [theme.breakpoints.down('md')]: {
        maxWidth: '3rem',
      },
    },
    listItemIcon: {
      color: 'inherit',
      minWidth: 'unset',
      margin: '5px 15px 5px 0',
    },
    listItemIconActive: {
      margin: '6px 0',
    },
    listItemText: {
      color: 'inherit',
      fontWeight: 'bold',
    },
    listItem: {
      display: 'flex',
      padding: 'unset',
      color: theme.palette.text.drawerMenu,
      justifyContent: 'center',
      width: '100%',
    },
    listItemGutters: {
      padding: '12px 30px',
    },
    listItemGuttersOpen: {
      width: drawerWidth,
      minWidth: '275px',
    },
    listItemGuttersClose: {
      padding: '8px',
    },
    link: {
      width: '100%',
    },
    active: {
      background: 'rgba(255, 255, 252, 0.3)',
      borderRadius: '40px',
      padding: '5px 20px',
      justifyContent: 'center',
      width: '100%',
      position: 'relative',
      color: theme.palette.text.hint,
    },
    activeAndOpened: { right: '10px' },
    topBar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    logoLink: {
      flex: 1,
      [theme.breakpoints.down('md')]: {
        maxWidth: 'max-content',
      },
    },
    form: {
      flex: 4,
      textAlign: 'center',
      padding: '0 15%',
      [theme.breakpoints.down('md')]: {
        flex: 2,
        margin: '0 20px',
        padding: '0',
      },
    },
    topBarSection: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    userMenu: {
      display: 'flex',
      alignItems: 'center',
    },
    userName: {
      marginRight: 10,
    },
    userIcon: {
      fontSize: '2rem',
    },
    topBarMiddleIcon: {
      margin: '0 10px',
    },
    topBarIcons: {
      fontSize: '2rem',
    },
    iconDiv: {
      minWidth: 'max-content',
      marginRight: 30,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);
