import axios, { AxiosRequestConfig } from 'axios';

// const URL = process.env.NODE_ENV === 'production' ? 'https://app-gestao.herokuapp.com/' : 'http://localhost:8000';
const URL = 'http://localhost:8000';

const baseConfig: AxiosRequestConfig = {
  baseURL: URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
};

export const auth = axios.create({
  ...baseConfig,
  baseURL: URL + '/authentication',
});

export const users = axios.create({
  ...baseConfig,
  baseURL: URL + '/users',
});

export const businesses = axios.create({
  ...baseConfig,
  baseURL: URL + '/businesses',
});

export const brands = axios.create({
  ...baseConfig,
  baseURL: URL + '/brands',
});

export const products = axios.create({
  ...baseConfig,
  baseURL: URL + '/products',
});

export const paymentMachines = axios.create({
  ...baseConfig,
  baseURL: URL + '/paymentMachines',
});

export const foodApps = axios.create({
  ...baseConfig,
  baseURL: URL + '/foodApps',
});