import { Box, CircularProgress, styled, Typography } from '@material-ui/core';
import { EmailOutlined, LockOutlined } from '@material-ui/icons';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Actions, useAppContext } from '../../../context/AppContext';
import ErrorMessage from '../../../errors/ErrorMessage';
import { Login, useLoginValidationSchema } from '../../../model/Login.entity';
import { Auth } from '../../../services/auth.service';
import { CustomButton } from '../../core/Buttons/CustomButton';
import Form from '../../core/Form/Form';
import Input from '../../core/Input/Input';
import TogglePasswordIcon from '../../core/TogglePasswordIcon';

const FormOptions = styled(Box)({
  width: '100%',
  padding: '0 5%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '5%',
});

const LoginForm = () => {
  const { dispatch } = useAppContext();
  const { values, handleChange, setFieldValue, handleSubmit, touched, errors } = useFormik<Login>({
    initialValues: new Login(),
    onSubmit,
    validationSchema: useLoginValidationSchema(),
  });
  const { email, password, showPassword } = values;
  const history = useHistory();
  const location: { state: { from: { pathname: string } } } = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState(from.pathname === '/' ? '' : 'É necessário estar logado para acessar esta página');

  async function onSubmit(values: Login) {
    try {
      setIsLoading(true);

      const user = await Auth.signin(values);

      dispatch({ type: Actions.SET_USER, payload: { user } });

      setIsLoading(false);
      history.replace(from.pathname !== '/' ? from : '/dashboard');
    } catch (error) {
      setErrorMessage(error.message);
      setIsLoading(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Input label='E-mail' id='email' type='email' name='email' value={email} onChange={handleChange} startIcon={<EmailOutlined color='disabled' />} />
      {touched.email && errors.email && <ErrorMessage errorMessage={errors.email} />}

      <Input
        label='Senha'
        name='password'
        onChange={handleChange}
        id='password'
        type={showPassword ? 'text' : 'password'}
        value={password}
        startIcon={<LockOutlined color='disabled' />}
        endIcon={<TogglePasswordIcon attribute='showPassword' toggle={showPassword} setValue={setFieldValue} />}
      />
      {touched.password && errors.password && <ErrorMessage errorMessage={errors.password} />}

      <FormOptions>
        <Link to='/'>
          <Typography color={'textSecondary'} variant={'body2'}>
            Esqueci a senha
          </Typography>
        </Link>
      </FormOptions>

      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}

      <CustomButton disabled={isLoading} type='submit' variant='contained' color='primary' size='large'>
        {isLoading ? <CircularProgress color='inherit' /> : 'Entrar'}
      </CustomButton>
    </Form>
  );
};

export default LoginForm;
