import { createContext, useContext } from 'react';
import { Alert } from '../model/Alert.entity';
import { BusinessPreference } from '../model/BusinessPreference.entity';
import { User } from '../model/User.entity';
import { Locale } from '../utils/locale';
import { AppActions, IAppContext, InitialState } from './Context.types';

export enum Actions {
  CHANGE_DRAWER = 'CHANGE_DRAWER',
  SET_USER = 'SET_USER',
  SET_APP_LOADING = 'SET_APP_LOADING',
  SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED',
  SET_THEME = 'SET_THEME',
  SET_ALERT = 'SET_ALERT',
  CHANGE_MAIN_BUSINESS_PAYMENT_MACHINE = 'CHANGE_MAIN_BUSINESS_PAYMENT_MACHINE',
  CHANGE_MAIN_BUSINESS_FOOD_APP = 'CHANGE_MAIN_BUSINESS_FOOD_APP',
}

export const INITIAL_STATE: InitialState = {
  drawerOpened: false,
  locale: Locale.PT_BR,
  user: new User(),
  isAppLoading: false,
  isAuthenticated: false,
  alert: new Alert(),
};

export function reducer(state: InitialState = INITIAL_STATE, action: AppActions) {
  switch (action.type) {
    case Actions.CHANGE_DRAWER:
      return { ...state, drawerOpened: !state.drawerOpened };
    case Actions.SET_USER:
      const newState = state;
      newState.user = action.payload.user;
      return newState;
    case Actions.SET_APP_LOADING:
      return { ...state, isAppLoading: action.payload.value };
    case Actions.SET_IS_AUTHENTICATED:
      return { ...state, isAuthenticated: action.payload.value };
    case Actions.SET_THEME:
      if (!state.user.preference) return state;
      state.user.preference.colorTheme = action.payload.value;
      return { ...state };
    case Actions.SET_ALERT:
      return { ...state, alert: action.payload.alert };
    case Actions.CHANGE_MAIN_BUSINESS_PAYMENT_MACHINE:
      let {businessPreference} = state.user.businesses[0];
      if (!businessPreference){ businessPreference = new BusinessPreference(); }
      businessPreference.mainBusinessPaymentMachine = action.payload.businessPaymentMachine
      return { ...state }
    case Actions.CHANGE_MAIN_BUSINESS_FOOD_APP:
      let {businessPreference: bPref} = state.user.businesses[0];
      if (!bPref){ bPref = new BusinessPreference(); }
      bPref.mainBusinessFoodApp = action.payload.businessFoodApp;
      return { ...state }
    default:
      return state;
  }
}

export const AppContext = createContext<IAppContext>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

export const useAppContext = () => useContext(AppContext);
