import { Button, capitalize, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, useTheme } from '@material-ui/core';
import { NightsStayRounded, SettingsSystemDaydreamRounded, WbSunnyRounded } from '@material-ui/icons';
import React, { useState } from 'react';
import { Actions, useAppContext } from '../../context/AppContext';
import useAlert from '../../hooks/useAlert';
import useAppLoading from '../../hooks/useAppLoading';
import { ColorTheme } from '../../model/ColorTheme.entity';
import { CustomDialogProps } from "../../model/CustomDialogProps";
import { UserService } from '../../services/user.service';
import IconMenuItem from '../core/RoundedMenu/IconMenuItem';

export const ThemeDialog: React.FC<CustomDialogProps<any>> = ({ handleClose, data, open }) => {
  const { state, dispatch } = useAppContext();
  const theme = useTheme();
  const { setAppLoading } = useAppLoading();
  const { successAlert, errorAlert } = useAlert();
  const [currentTheme] = useState(state.user.preference.colorTheme);

  const currentValue = capitalize(state.user.preference.colorTheme);

  const handleSave = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      setAppLoading(true);
      const user = await UserService.updateUserPreference(state.user, state.user.preference.colorTheme);
      dispatch({ type: Actions.SET_USER, payload: { user } });
      successAlert();
    } catch (err) {
      errorAlert(err.message ?? err);
    } finally {
      setAppLoading(false);
      handleClose && handleClose(e);
    }
  };

  const handleCancel = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAppLoading(true);
    dispatch({ type: Actions.SET_THEME, payload: { value: currentTheme ?? ColorTheme.SYSTEM } });

    setAppLoading(false);
    handleClose && handleClose(e);
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Definir tema</DialogTitle>
      <DialogContent>
        <DialogContentText>Defina o tema desejado</DialogContentText>
        <DialogContentText>Atual: {currentValue}</DialogContentText>
        <Select fullWidth value={state.user.preference.colorTheme} onChange={(e: React.ChangeEvent<any>) => dispatch({ type: Actions.SET_THEME, payload: { value: e.target.value } })}>
          {Object.values(ColorTheme).map(colorTheme => (
            <IconMenuItem
              key={colorTheme}
              color={theme.palette.text.secondary}
              selected={state.user.preference.colorTheme === colorTheme}
              text={capitalize(colorTheme)}
              icon={getIcon(colorTheme)}
              value={colorTheme}
            />
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color='primary'>
          Cancelar
        </Button>
        <Button onClick={handleSave} variant='contained' color='primary'>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function getIcon(theme: ColorTheme) {
  return theme === ColorTheme.DARK ? NightsStayRounded : theme === ColorTheme.LIGHT ? WbSunnyRounded : SettingsSystemDaydreamRounded;
}
