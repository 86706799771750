import { ColorTheme } from '../model/ColorTheme.entity';
import { Salary } from '../model/Salary.entity';
import { User } from '../model/User.entity';
import { users } from './precin_api';

export const UserService = {
  async updateUserPreference(user: User, theme?: ColorTheme) {
    try {
      const { data } = await users.put(`/${user.id}/user-preference`, { colorTheme: theme ?? 'system' });

      return User.buildUser(data);
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async getSalary(user: User) {
    const emptySalary = new Salary();
    try {
      const { data } = await users.get<Salary>(`/${user.id}/salary`);

      if (!data) return emptySalary;

      return data;
    } catch (err) {
      if (err.response.status === 404) {
        return emptySalary;
      }
      throw new Error(err.response.data.message);
    }
  },

  async updateSalary(user: User, salary: Salary) {
    try {
      const { data } = await users.put<Salary>(`/${user.id}/salary`, Salary.from(salary));

      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async createSalary(user: User, salary: Salary) {
    try {
      const { data } = await users.post<Salary>(`/${user.id}/salary`, salary);
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },
};
