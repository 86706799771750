import { useState } from 'react';

const useForm = <T>(defaultValues: T) => {
  const [values, setValues] = useState(defaultValues);

  const setValue = <T>(key: string | number | symbol, value: T) => {
    setValues({
      ...values,
      [key]: value,
    });
  };

  const handleChange = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.name);

    setValue(e.target.name ?? e.target.id, e.target.value);
  };

  return { values, setValue, handleChange };
};

export default useForm;