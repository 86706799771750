import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import useAuth from '../../hooks/useAuth';

export const PrivateRoute: React.FC<RouteProps> = ({ children, component, ...rest }) => {
  const { Loading, AuthEffect } = useAuth();
  const { state } = useAppContext();

  AuthEffect();

  return state.isAppLoading ? (<Loading />) : (
    <Route
      {...rest}
      render={({ location }) =>
        state.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
