import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useDashboardStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboardContainer: {
      width: '100%',
      height: '100%',
      padding: '5%',
      paddingLeft: '30%',
      marginTop: 100,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '25%',
      },
      [theme.breakpoints.up('xl')]: {
        paddingLeft: '15%',
      },
    },
    paddingLeft: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '15%',
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: '10%',
      },
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      [theme.breakpoints.down('md')]: {
        fontSize: '2rem',
      },
    },
    subtitle: {
      marginTop: 10,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.5rem',
      },
    },
    titleSection: {
      display: 'flex',
      alignItems: 'center',
      marginBlock: 10,
      '& h1': {
        marginRight: 20,
      },
    },
    titleButton: {
      backgroundColor: theme.palette.info.main,
      fontSize: '1.5rem',
      color: theme.palette.text.drawerMenu,
      marginLeft: theme.spacing(),
    },
  })
);
