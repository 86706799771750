import { Box } from '@material-ui/core';
import React from 'react';
import RecipeCard from '../../components/cards/RecipeCard';
import DashboardContainer from '../../components/containers/DashboardContainer/DashboardContainer';

const recipeRates = [
  {
    type: 'Dinheiro',
    value: 28.37,
  },
  {
    type: 'Cartão',
    value: 29.37,
  },
  {
    type: 'App',
    value: 30,
  },
];

const recipes = [
  {
    id: 1,
    title: 'Brigadeiro',
    recipeRates,
  },
  {
    id: 2,
    title: 'Bolo',
    recipeRates,
  },
  {
    id: 3,
    title: 'Coxinha',
    recipeRates,
  },
  {
    id: 4,
    title: 'Massa',
    recipeRates,
  },
  {
    id: 5,
    title: 'Kibe',
    recipeRates,
  },
  {
    id: 6,
    title: 'Risole',
    recipeRates,
  },
  {
    id: 7,
    title: 'Risole',
    recipeRates,
  },
  {
    id: 8,
    title: 'Risole',
    recipeRates,
  },
  {
    id: 9,
    title: 'Risole',
    recipeRates,
  },
  {
    id: 10,
    title: 'Risole',
    recipeRates,
  },
  {
    id: 11,
    title: 'Risole',
    recipeRates,
  },
  {
    id: 12,
    title: 'Risole',
    recipeRates,
  },
];

const DashboardHome = () => {
  return (
    <DashboardContainer page={'dashboard'} title={'Principais Receitas'}>
      <Box marginY='3%' display='grid' gridTemplateColumns='repeat(auto-fit, minmax(250px, 1fr))' gridColumnGap='30px' gridRowGap='45px' justifyItems='center'>
        {recipes.map(recipe => (
          <RecipeCard key={recipe.id} title={recipe.title} recipeRates={recipe.recipeRates} />
        ))}
      </Box>
    </DashboardContainer>
  );
};

export default DashboardHome;
