import { Business } from './Business.entity';
import { Salary } from './Salary.entity';
import { UserPreference } from './user-preference.entity';

export interface UserPayload {
  id: string;
  email: string;
  cpf: string;
  firstName: string;
  lastName: string;
  active: boolean;
  roles: string[];
  businesses: Business[];
  preference: UserPreference;
  salary?: Salary;
}
export class User {
  id?: string = '';
  firstName?: string = '';
  lastName?: string = '';
  email?: string = '';
  password?: string = '';
  cpf?: string = '';
  businesses: Business[] = [];
  salary?: Salary;
  preference: UserPreference = new UserPreference();

  static buildUser(payload: UserPayload) {
    const user = new User();
    const business = new Business();

    user.id = payload.id;
    user.firstName = payload.firstName;
    user.lastName = payload.lastName;
    user.email = payload.email;
    user.cpf = payload.cpf;

    business.id = payload.businesses[0].id;
    business.cnpj = payload.businesses[0].cnpj;
    business.businessName = payload.businesses[0].businessName;
    business.businessCostPerMinute = payload.businesses[0].businessCostPerMinute;

    user.businesses = [business];

    if (payload.preference) {
      user.preference.colorTheme = payload.preference.colorTheme;
      user.preference.systemLanguage = payload.preference.systemLanguage;
    }

    user.salary = payload.salary;

    return user;
  }
}
