import { FilledInput, FilledInputProps, FormControl, InputAdornment, InputLabel } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { useStyles } from './useStyles';

interface InputProps {
  label: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  labelClass?: string;
  inputClass?: string;
}

const Input: React.FC<InputProps & Omit<FilledInputProps, keyof InputProps>> = ({ fullWidth, label, onChange, startIcon, endIcon, color, labelClass, inputClass, id, ...other }) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth={fullWidth} className={classes.formControl}>
      <InputLabel classes={{ root: labelClass }} className={classes.inputLabel} htmlFor={id}>
        {label}
      </InputLabel>
      <FilledInput
        className={`${classes.input} ${inputClass}`}
        onChange={onChange}
        startAdornment={startIcon && <InputAdornment position='start'>{startIcon}</InputAdornment>}
        endAdornment={endIcon && <InputAdornment position='start'>{endIcon}</InputAdornment>}
        id={id}
        color={color}
        {...other}
      />
    </FormControl>
  );
};

Input.defaultProps = {
  fullWidth: true,
};

export default Input;
