import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext';
import useAuth from '../../../hooks/useAuth';

export const LoadingRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { Loading, AuthEffect } = useAuth();
  const { state } = useAppContext();

  AuthEffect();

  return state.isAppLoading ? <Loading /> : <Route {...rest} render={() => (state.isAuthenticated ? <Redirect to={{ pathname: '/dashboard' }} /> : children)} />;
};
