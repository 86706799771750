import { Box, ThemeProvider, Typography, useMediaQuery } from '@material-ui/core';
import React, { ReactNode, useMemo } from 'react';
import mobileDashboard from '../../../assets/img/login.svg';
import logo from '../../../assets/img/precyn.svg';
import loginThemeConfig from '../../../theme/loginTheme';
import './LoginContainer.css';

const STANDARD_TEXT = 'Um aplicativo para precificar e gerir as finanças da sua empresa';

const LoginContainer = ({ children, text = STANDARD_TEXT, img = mobileDashboard, imgSize = '100%' }: { children: ReactNode; text?: string; img?: string; imgSize?: string }) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const getLoginThemeConfig = loginThemeConfig(prefersDarkMode);

  const loginTheme = useMemo(() => getLoginThemeConfig, [getLoginThemeConfig]);

  return (
    <ThemeProvider theme={loginTheme}>
      <main className='login_container'>
        <section className='mobile_dashboard'>
          <img src={logo} alt='logo' className='logo' />
          <img src={img} alt='imagem dashboard' style={{ width: imgSize }} className='main_image' />
        </section>

        <Box display='flex' flexDirection='column' flex={1} padding='0 3%' height='100%' className='info_section' color='common.black'>
          <section className='text_container'>
            <Typography variant='h3' color={'inherit'} component='h1'>
              Bem-vindo(a) ao app precyn
            </Typography>
            <Typography variant='h5' color={'inherit'} component='h2'>
              {text}
            </Typography>
          </section>
          {children}
        </Box>
      </main>
    </ThemeProvider>
  );
};

export default LoginContainer;
