import React from 'react';
import LoginContainer from '../../components/containers/LoginContainer/LoginContainer';
import ButtonLink from '../../components/core/Buttons/ButtonLink/ButtonLink';
import LoginForm from '../../components/forms/LoginForm/LoginForm';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './LoginHome.css';

const LoginHome = () => {
  const { width } = useWindowDimensions();

  return (
    <LoginContainer>
      <section className='login_form'>
        <LoginForm />
      </section>

      <section className='button_container'>
        {width < 500 && <ButtonLink to='/signin'>Entrar</ButtonLink>}
        <ButtonLink to='/signup' color='secondary'>
          Criar conta
        </ButtonLink>
      </section>
    </LoginContainer>
  );
};

export default LoginHome;
