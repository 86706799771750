import { Box } from '@material-ui/core';
import DashboardContainer from '../../components/containers/DashboardContainer/DashboardContainer';
import { MyAccordion } from '../../components/core/Accordion/CustomAccordion';
import ExpensesTable from './ExpensesTable';
import FoodAppsTable from './FoodAppsTable';
import PaymentMachinesTable from './PaymentMachinesTable';
import SalaryTable from './SalaryTable';

const Finances = () => {
  return (
    <DashboardContainer page={'finances'} title={'Salário e Custos Fixos'}>
      <Box marginY='2%'>
        <MyAccordion summaryText='Meu Salário'>
          <SalaryTable />
        </MyAccordion>
      </Box>

      <Box marginY='2%'>
        <MyAccordion summaryText='Meus Custos'>
          <ExpensesTable />
        </MyAccordion>
      </Box>

      <Box marginY='2%'>
        <MyAccordion summaryText='Minhas Máquinas de Pagamento'>
          <PaymentMachinesTable />
        </MyAccordion>
      </Box>

      <Box marginY='2%'>
        <MyAccordion summaryText='Meus Aplicativos de Entrega'>
          <FoodAppsTable />
        </MyAccordion>
      </Box>
    </DashboardContainer>
  );
};

export default Finances;
