import { Button, Divider, Typography } from '@material-ui/core';
import { AccountBalance, AttachMoney, Fastfood, PhonelinkRing, PostAddOutlined, Receipt } from '@material-ui/icons';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useAppContext } from '../../../context/AppContext';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { CustomAlert } from '../../core/Alert/Alert';
import DrawerMenu from '../../core/DrawerMenu/DrawerMenu';
import { Route } from '../../core/DrawerMenu/usePages';
import IconMenuItem from '../../core/RoundedMenu/IconMenuItem';
import RoundedMenu from '../../core/RoundedMenu/RoundedMenu';
import TopBar from '../../core/TopBar/TopBar';
import NewExpenseDialog from '../../dialogs/NewExpenseDialog';
import NewFoodAppDialog from '../../dialogs/NewFoodAppDialog';
import NewPaymentMachineDialog from '../../dialogs/NewPaymentMachineDialog';
import NewProductDialog from '../../dialogs/NewProductDialog';
import NewRecipeDialog from '../../dialogs/NewRecipeDialog';
import { useDashboardStyles } from './useDashboardStyles';

const DashboardContainer = (props: { title?: string; page: Route; children?: React.ReactNode }) => {
  const classes = useDashboardStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement | Element>(null);
  const { state } = useAppContext();
  const { width } = useWindowDimensions();

  const [dialogs, setDialogs] = useState({ costDialog: false, productDialog: false, recipeDialog: false, paymentMachineDialog: false, foodAppDialog: false });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <main
      className={clsx(classes.dashboardContainer, {
        [classes.paddingLeft]: !state.drawerOpened,
      })}>
      <TopBar />
      <DrawerMenu activeMenu={props.page} />
      <section className={classes.titleSection}>
        <Typography variant={width > 500 ? 'h2' : 'h3'} color={'textPrimary'} className={classes.title}>
          Minha Gestão
        </Typography>
        <Button aria-controls='dashboard-menu' aria-haspopup='true' variant='contained' size={'large'} classes={{ contained: classes.titleButton }} onClick={handleClick}>
          <PostAddOutlined fontSize={'inherit'} color={'inherit'} />
        </Button>
        <RoundedMenu id={'dashboard-menu'} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
          <IconMenuItem text='Novo custo' icon={AttachMoney} onClick={() => setDialogs({ ...dialogs, costDialog: true })} />
          <IconMenuItem text='Novo produto' icon={Fastfood} onClick={() => setDialogs({ ...dialogs, productDialog: true })} />
          <IconMenuItem text='Nova receita' icon={Receipt} onClick={() => setDialogs({ ...dialogs, recipeDialog: true })} />
          <IconMenuItem text='Nova máquina de pagamento' icon={AccountBalance} onClick={() => setDialogs({ ...dialogs, paymentMachineDialog: true })} />
          <IconMenuItem text='Novo Aplicativo de Entrega' icon={PhonelinkRing} onClick={() => setDialogs({ ...dialogs, foodAppDialog: true })} />
        </RoundedMenu>
      </section>
      <Divider />
      <Typography variant={'h3'} color={'textSecondary'} className={classes.subtitle}>
        {props.title}
      </Typography>
      {props.children}
      <NewExpenseDialog open={dialogs.costDialog} handleClose={() => setDialogs({ ...dialogs, costDialog: false })} />
      <NewProductDialog open={dialogs.productDialog} handleClose={() => setDialogs({ ...dialogs, productDialog: false })} />
      <NewRecipeDialog open={dialogs.recipeDialog} handleClose={() => setDialogs({ ...dialogs, recipeDialog: false })} />
      <NewPaymentMachineDialog open={dialogs.paymentMachineDialog} handleClose={() => setDialogs({ ...dialogs, paymentMachineDialog: false })} />
      <NewFoodAppDialog open={dialogs.foodAppDialog} handleClose={() => setDialogs({ ...dialogs, foodAppDialog: false })} />
      <CustomAlert />
    </main>
  );
};

export default DashboardContainer;
