export type Route = 'dashboard' | 'finances' | 'ingredients' | 'recipes';
export interface Page {
  title: string;
  route: Route;
}

const pages: Page[] = [
  { title: 'Início', route: 'dashboard' },
  { title: 'Salário e Custos Fixos', route: 'finances' },
  { title: 'Lista de Ingredientes', route: 'ingredients' },
  { title: 'Lista de Receitas', route: 'recipes' },
];

const usePages = () => pages;

export default usePages;