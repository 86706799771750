import { Card, CardProps, styled } from '@material-ui/core';
import React from 'react';

interface MyCardProps {
  backgroundColor: string | undefined;
  fullWidth: boolean;
  boxShadow: string;
}

const ColoredCard = styled(({ boxShadow, backgroundColor, fullWidth, ...other }: MyCardProps & Omit<CardProps, keyof MyCardProps>) => <Card {...other} />)({
  backgroundColor: ({ backgroundColor }: MyCardProps) => backgroundColor,
  width: ({ fullWidth }: MyCardProps) => (fullWidth ? '100%' : 'auto'),
  boxShadow: ({ boxShadow }: MyCardProps) => boxShadow,
});

export default ColoredCard;
