import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Step, StepLabel, Stepper } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import { CustomDialogProps } from "../../model/CustomDialogProps";
import { Recipe } from '../../model/Recipe.entity';
import { Carousel } from '../core/Carousel/Carousel';
import NewRecipeFormInstructions from '../forms/NewRecipeForm/NewRecipeFormInstructions';
import NewRecipeFormMain from '../forms/NewRecipeForm/NewRecipeFormMain';
import NewRecipeFormProducts from '../forms/NewRecipeForm/NewRecipeFormProducts';

const steps = ['Dados iniciais', 'Ingredientes', 'Instruções de Preparo'];

const NewRecipeDialog: React.FC<CustomDialogProps<Recipe>> = ({ data, open, handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Nova Receita</DialogTitle>
      <DialogContent>
        <DialogContentText>Crie uma nova receita para sua empresa</DialogContentText>
        <Carousel
          index={activeStep}
          onChangeIndex={step => {
            setActiveStep(step);
          }}>
          <NewRecipeFormMain recipe={data as Recipe} />
          <NewRecipeFormProducts />
          <NewRecipeFormInstructions />
        </Carousel>
        <Stepper activeStep={activeStep}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Button disabled={activeStep === 0} onClick={() => setActiveStep(prevStep => --prevStep)}>
          Voltar
        </Button>

        {activeStep === steps.length - 1 ? (
          <Button onClick={handleClose} variant='contained' color='primary'>
            Salvar
          </Button>
        ) : (
          <Button onClick={() => setActiveStep(prevStep => ++prevStep)} variant='contained' color='primary'>
            Próximo
          </Button>
        )}
      </DialogActions>
      <Box position='absolute' top='1%' right='1%'>
        <IconButton onClick={handleClose}>
          <CloseOutlined />
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default NewRecipeDialog;
