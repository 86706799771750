import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';

interface MyAutocompleteProps {
  setOptions: React.Dispatch<React.SetStateAction<any>>;
  optionAttribute: string;
  label: string;
  renderInput?: any;
  freeSolo?: boolean;
  autoFocus?: boolean;
  getOptionSelected?: (option: any, value: any) => boolean;
  customGetOptionLabel?: (option: any) => string;
}

type CustomAutocompleteProps = MyAutocompleteProps & Omit<AutocompleteProps<any, false, false, false>, keyof MyAutocompleteProps>;

export const AsyncAutocomplete: React.FC<CustomAutocompleteProps> = ({
  options,
  freeSolo,
  optionAttribute,
  getOptionSelected = (option, value) => option[optionAttribute] === value[optionAttribute],
  autoFocus,
  loading,
  setOptions,
  label,
  ref,
  customGetOptionLabel,
  ...other
}) => {
  const [acOpen, setAcOpen] = useState(false);

  useEffect(() => {
    if (!acOpen) {
      setOptions([]);
    }
  }, [acOpen, setOptions]);

  return (
    <Autocomplete
      {...other}
      freeSolo={freeSolo}
      open={acOpen}
      onOpen={() => {
        setAcOpen(true);
      }}
      onClose={() => {
        setAcOpen(false);
      }}
      getOptionSelected={getOptionSelected}
      getOptionLabel={customGetOptionLabel ? customGetOptionLabel : option => option[optionAttribute] ?? ''}
      options={options}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          margin='dense'
          label={label}
          type='text'
          fullWidth
          autoFocus={autoFocus ?? false}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
