import { BusinessFoodApp } from './BusinessFoodApp.entity';
import { BusinessPaymentMachine } from './BusinessPaymentMachine.entity';
import { RecipeInstruction } from './RecipeInstruction.entity';
import { RecipeBusinessProduct } from './RecipeProduct.entity';

export class Recipe {
  id: string = '';
  name: string = '';
  productType: string = '';
  currentUnitPrice: number = 0;
  currentUnitProfit: number = 0;
  suggestedUnitPrice: number = 0;
  suggestedUnitCardPrice: number = 0;
  suggestedUnitFoodAppPrice: number = 0;
  shelfLife: string = '';
  yield: number = 0;
  preparationTime: number = 0;
  desiredProfit: number = 30;
  laborUnitCost: number = 0;
  fixedAndVariableUnitCost: number = 0;
  suppliesUnitCost: number = 0;
  totalUnitCost: number = 0;
  createdAt: string = '';
  updatedAt: string = '';
  recipeBusinessProduct?: RecipeBusinessProduct[] = [];
  instructions?: RecipeInstruction[] = [];
  businessPaymentMachine: BusinessPaymentMachine = new BusinessPaymentMachine();
  businessFoodApp: BusinessFoodApp = new BusinessFoodApp();
}
