import { Button, TableBody, TableContainer } from '@material-ui/core';
import { Create } from '@material-ui/icons';
import React, { useState } from 'react';
import { CustomPaper } from '../../components/core/Paper/CustomPaper';
import { CustomTable, CustomTableRow, LeftTableCell, RightTableCell } from '../../components/core/Table/CustomTable';
import { BlackTypographyDymamicWeight } from '../../components/core/Typography/CustomTypography';
import SalaryDialog from '../../components/dialogs/SalaryDialog';
import { useAppContext } from '../../context/AppContext';
import useFormatter from '../../hooks/useFormatter';

const SalaryTable: React.FC = () => {
  const { state } = useAppContext();
  const { currencyFormatter } = useFormatter();
  const { salary } = state.user;
  const [openSalaryDialog, setOpenSalaryDialog] = useState<boolean>(false);

  const rows = salary
    ? [
        { id: 1, rowName: 'Quanto quero ganhar?', data: currencyFormatter(salary.salaryValue) },
        { id: 2, rowName: 'Quanto dias vou trabalhar no mês', data: salary.businessDays },
        { id: 3, rowName: 'Quantas horas vou trabalhar por dia', data: salary.businessHours },
        { id: 4, rowName: 'Meu valor dia', data: currencyFormatter(salary.dailyRate) },
        { id: 5, rowName: 'Meu valor hora', data: currencyFormatter(salary.hourRate) },
      ]
    : [{ id: 1, rowName: 'Salário não definido', data: 'Definir' }];

  return (
    <TableContainer component={CustomPaper}>
      <CustomTable size='medium' aria-label='Salary table'>
        <TableBody>
          {rows.map(row => (
            <CustomTableRow key={row.id}>
              <LeftTableCell align='left'>{row.rowName}</LeftTableCell>
              <RightTableCell align='center'>
                <strong>
                  {row.data === 'Definir' ? (
                    <Button size='small' startIcon={<Create color='action' />} onClick={() => setOpenSalaryDialog(true)}>
                      <BlackTypographyDymamicWeight fontWeight='bold'>Definir</BlackTypographyDymamicWeight>
                    </Button>
                  ) : (
                    row.data
                  )}
                </strong>
              </RightTableCell>
            </CustomTableRow>
          ))}
        </TableBody>
      </CustomTable>
      <SalaryDialog open={openSalaryDialog} handleClose={() => setOpenSalaryDialog(false)} />
    </TableContainer>
  );
};

export default SalaryTable;
