import { Box, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import React, { FC } from 'react';
import { WhitePaperLightOpacity } from '../components/core/Paper/CustomPaper';

interface Props {
  errorMessage: string;
}

const ErrorMessage: FC<Props> = ({ errorMessage }) => {
  return (
    <Box padding='1% 2%' width='100%' marginBottom='3%' borderRadius='10px' color='error.dark' component={WhitePaperLightOpacity} display='flex' alignItems='center' justifyContent='space-around'>
      <ErrorOutline />
      <Typography color='error' component='span' variant='body1'>
        {'  ' + errorMessage}
      </Typography>
    </Box>
  );
};

export default ErrorMessage;
