import { useAppContext } from '../context/AppContext';
import { Locale, LocaleMessage } from '../utils/locale';

export type LabelKey = 'firstName' | 'lastName' | 'confirmEmail' | 'password' | 'confirmPassword' | 'createAccount' | 'expenseName' | 'brandName';
type Label = { [k in LabelKey]: LocaleMessage };

const labels: Label = {
	firstName: {
		[Locale.PT_BR]: 'Nome',
		[Locale.EN_US]: 'First Name',
	},

	lastName: {
		[Locale.PT_BR]: 'Sobrenome',
		[Locale.EN_US]: 'Last Name',
	},

	confirmEmail: {
		[Locale.PT_BR]: 'Confirmar E-mail',
		[Locale.EN_US]: 'Confirm E-mail',
	},

	password: {
		[Locale.PT_BR]: 'Senha',
		[Locale.EN_US]: 'Password',
	},

	confirmPassword: {
		[Locale.PT_BR]: 'Confirmar Senha',
		[Locale.EN_US]: 'Confirm Password',
	},

	createAccount: {
		[Locale.PT_BR]: 'Criar conta',
		[Locale.EN_US]: 'Create Account',
	},

	expenseName: {
		[Locale.PT_BR]: 'Nome de Despesa',
		[Locale.EN_US]: 'Expense Name',
	},

	brandName: {
		[Locale.PT_BR]: 'Nome da Marca',
		[Locale.EN_US]: 'Brand Name',
	},
};

const useLabel = () => {
	const { state } = useAppContext();

	function getLabel(key: LabelKey): string {
		if (key) {
			return labels[key][state.locale];
		}
		throw new Error('Missing key value');
	}

	return { getLabel };
};

export default useLabel;
