/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress, Grid, IconButton, TableBody, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { Create, DeleteOutlined, EditOutlined, Star } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { CustomPaper } from "../../components/core/Paper/CustomPaper";
import { CellColorInherit, CustomTable, CustomTableRow, LeftTableCell, MiddleTableCell, RightTableCell } from "../../components/core/Table/CustomTable";
import { BlackTypography } from "../../components/core/Typography/CustomTypography";
import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import NewPaymentMachineDialog from "../../components/dialogs/NewPaymentMachineDialog";
import { useAppContext } from "../../context/AppContext";
import useAlert from "../../hooks/useAlert";
import useFormatter from "../../hooks/useFormatter";
import useMultipleDialogs, { ConfirmDialogState } from "../../hooks/useMultipleDialogs";
import { BusinessPaymentMachine } from "../../model/BusinessPaymentMachine.entity";
import { BusinessService } from "../../services/business.service";

interface PaymentMachinesTableState {
  bPaymentMachines: BusinessPaymentMachine[];
}

const PaymentMachinesTable: React.FC = () => {
  const { state } = useAppContext();
  const { user } = state;
  const [isLoading, setIsLoading] = useState(true);
  const headers = ['Marca', 'Modelo', 'Taxa de Débito', 'Taxa de Crédito', 'Ação'];
  const [data, setData] = useState<PaymentMachinesTableState>({ bPaymentMachines: [] });
  const { successAlert, errorAlert } = useAlert();
  const {getDialog, toggleDialog} = useMultipleDialogs<BusinessPaymentMachine>();
  const {percentageFormatter} = useFormatter();
  const [confirmDialogs, setConfirmDialogs] = useState<ConfirmDialogState<BusinessPaymentMachine>[]>([]);
  const [editPaymentMachineDialogs, setEditPaymentMachineDialogs] = useState<ConfirmDialogState<BusinessPaymentMachine>[]>([]);
  const [openPaymentMachineDialog, setOpenPaymentMachineDialog] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (!user.businesses || data.bPaymentMachines.length > 0) {
        setIsLoading(false);
        return;
      }
      try {
        const bPaymentMachines = await BusinessService.getBusinessPaymentMachines(user.businesses[0].id);
        const mainBPaymentMachine = bPaymentMachines.find(bPaymentMachine => bPaymentMachine.id === user.businesses[0].businessPreference?.mainBusinessPaymentMachine?.id);
        if(mainBPaymentMachine) {
          mainBPaymentMachine.main = true;
        }
        setData({ bPaymentMachines });
        const bPaymentMachineDialogs = bPaymentMachines.map(bPaymentMachine => ({ data: bPaymentMachine, isOpen: false } as ConfirmDialogState<BusinessPaymentMachine>));
        setConfirmDialogs(bPaymentMachineDialogs);
        setEditPaymentMachineDialogs(bPaymentMachineDialogs);
      } catch (err) {
        errorAlert(err.message);
      } finally {
        setIsLoading(false);
      }
    })();

    return () => {
      setIsLoading(false);
    };
  }, [])

  const handleDelete = (bPaymentMachineId: string) => async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!user.businesses) {
      return;
    }

    try {
      setIsLoading(true);

      const updatedBPaymentMachines = data.bPaymentMachines.filter(bPaymentMachine => bPaymentMachine.id !== bPaymentMachineId);

      await BusinessService.deleteBusinessPaymentMachine(user.businesses[0].id, bPaymentMachineId);

      setData({ bPaymentMachines: updatedBPaymentMachines });

      successAlert();
    } catch (err) {
      errorAlert(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? (
		<CircularProgress />
	) : (
		<TableContainer component={CustomPaper}>
			<CustomTable size='medium' aria-label='Payment Machines Table'>
				<TableHead style={{ color: '#000' }}>
					<TableRow>
						{headers.map(header => (
							<CellColorInherit bold align='center'>
								{header}
							</CellColorInherit>
						))}
					</TableRow>
				</TableHead>
				<TableBody style={{ color: '#000' }}>
					{data.bPaymentMachines.length > 0 ? (
						data.bPaymentMachines.map(bPaymentMachine => (
							<CustomTableRow key={bPaymentMachine.id}>
								<LeftTableCell align='center'>
									<Grid container wrap='nowrap' spacing={1} justifyContent='center' alignItems='center' alignContent='center'>
										{bPaymentMachine.main && (
											<Grid item>
												<Star color='primary' />
											</Grid>
										)}
										<Grid item>{bPaymentMachine.paymentMachine.manufacturer.name}</Grid>
									</Grid>
								</LeftTableCell>
								<MiddleTableCell align='center'>{bPaymentMachine.paymentMachine.name}</MiddleTableCell>
								<MiddleTableCell align='center'>{percentageFormatter(bPaymentMachine.debitFee / 100)}</MiddleTableCell>
								<MiddleTableCell align='center'>{percentageFormatter(bPaymentMachine.creditFee / 100)}</MiddleTableCell>
								<RightTableCell align='center'>
									<IconButton onClick={toggleDialog(bPaymentMachine.id, editPaymentMachineDialogs, setEditPaymentMachineDialogs)} color={'inherit'}>
										<EditOutlined />
									</IconButton>
									<IconButton onClick={toggleDialog(bPaymentMachine.id, confirmDialogs, setConfirmDialogs)} value={bPaymentMachine.id} color={'inherit'}>
										<DeleteOutlined />
									</IconButton>
								</RightTableCell>
								<NewPaymentMachineDialog data={bPaymentMachine} open={getDialog(editPaymentMachineDialogs, bPaymentMachine)} handleClose={toggleDialog(bPaymentMachine.id, editPaymentMachineDialogs, setEditPaymentMachineDialogs)} />
								<ConfirmDialog handleConfirm={handleDelete(bPaymentMachine.id)} open={getDialog(confirmDialogs, bPaymentMachine)} handleClose={toggleDialog(bPaymentMachine.id, confirmDialogs, setConfirmDialogs)} value={bPaymentMachine.paymentMachine.manufacturer.name + ' ' + bPaymentMachine.paymentMachine.name} />
							</CustomTableRow>
						))
					) : (
						<CustomTableRow>
							<LeftTableCell colSpan={3} align='center'>
								Nenhuma Máquina Registrada
							</LeftTableCell>
							<RightTableCell colSpan={2} align='center'>
								<Button startIcon={<Create color='action' />} onClick={() => setOpenPaymentMachineDialog(true)}>
									<BlackTypography>Registrar</BlackTypography>
								</Button>
							</RightTableCell>
						</CustomTableRow>
					)}
				</TableBody>
			</CustomTable>
			<NewPaymentMachineDialog open={openPaymentMachineDialog} handleClose={() => setOpenPaymentMachineDialog(false)} />
		</TableContainer>
	);
}

export default PaymentMachinesTable;