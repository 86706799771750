import { Box, Button, Checkbox, CircularProgress, Grid, InputAdornment, List, ListItem, ListItemIcon, ListItemText, TextField, useTheme } from '@material-ui/core';
import { ChevronLeftRounded, ChevronRightRounded, DoubleArrowRounded, SearchOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../../context/AppContext';
import useAlert from '../../../hooks/useAlert';
import useTransferList from '../../../hooks/useTransferList';
import { BusinessProduct } from '../../../model/BusinessProduct.entity';
import { Product } from '../../../model/Product.entity';
import { BusinessService } from '../../../services/business.service';
import { BluePaper } from '../../core/Paper/CustomPaper';
import { GreyTypography } from '../../core/Typography/CustomTypography';

interface NewRecipeFormProductsProps {}

const NewRecipeFormProducts: React.FC<NewRecipeFormProductsProps> = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<BusinessProduct[]>([]);
  const { state } = useAppContext();
  const { user } = state;
  const { errorAlert } = useAlert();
  const { left, right, checked, leftChecked, rightChecked, handleAllRight, handleAllLeft, handleCheckedRight, handleCheckedLeft, handleToggle, setLeft, setRight } = useTransferList<BusinessProduct>(
    products,
    []
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (!user.businesses) {
        setIsLoading(false);
        return;
      }
      try {
        const products = await BusinessService.getBusinessProducts(user.businesses[0].id);
        setProducts(products);
      } catch (err) {
        errorAlert(err.message ?? err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [user.businesses, errorAlert]);

  function filterProducts(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, setFunction: Function, rawProducts: Product[]) {
    const { value } = e.currentTarget;
    if (!value) {
      setFunction(products);
      return;
    }

    const filteredProducts = products.filter(businessProduct => {
      if (businessProduct.customProductName) {
        return businessProduct.customProductName.toLowerCase().search(value.toLowerCase()) !== -1;
      } else {
        return businessProduct.product.name.toLowerCase().search(value.toLowerCase()) !== -1;
      }
    });

    setFunction(filteredProducts);
  }

  const customList = (items: BusinessProduct[], filter: Function, setFunction: Function) => {
    return (
      <BluePaper>
        <Box padding='5%'>
          <TextField
            variant='filled'
            onChange={e => filter(e, setFunction, items)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchOutlined />
                </InputAdornment>
              ),
              style: { color: theme.palette.grey[100] },
            }}
          />
        </Box>
        <List dense component='div' role='list'>
          {items.map(businessProduct => {
            const labelId = `transfer-list-item-${businessProduct.id}-label`;

            return (
              <ListItem key={businessProduct.id} role='listitem' button onClick={handleToggle(businessProduct)}>
                <ListItemIcon>
                  <Checkbox style={{ color: theme.palette.grey[100] }} checked={checked.indexOf(businessProduct) !== -1} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />
                </ListItemIcon>
                <ListItemText id={labelId} primary={<GreyTypography>{businessProduct.customProductName ?? businessProduct.product.name}</GreyTypography>} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </BluePaper>
    );
  };

  return (
    <Grid container spacing={2} justify='center' alignItems='center' wrap={'nowrap'} component='div'>
      <Grid item>{isLoading ? <CircularProgress /> : customList(left, filterProducts, setLeft)}</Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center'>
          <Button variant='outlined' size='small' onClick={handleAllRight} disabled={left.length === 0} aria-label='move all right'>
            <DoubleArrowRounded />
          </Button>
          <Button style={{ margin: '10px 0' }} variant='outlined' size='small' onClick={handleCheckedRight} disabled={leftChecked.length === 0} aria-label='move selected right'>
            <ChevronRightRounded />
          </Button>
          <Button style={{ marginBottom: '10px' }} variant='outlined' size='small' onClick={handleCheckedLeft} disabled={rightChecked.length === 0} aria-label='move selected left'>
            <ChevronLeftRounded />
          </Button>
          <Button variant='outlined' size='small' onClick={handleAllLeft} disabled={right.length === 0} aria-label='move all left'>
            <DoubleArrowRounded style={{ transform: 'rotate(180deg)' }} />
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right, filterProducts, setRight)}</Grid>
    </Grid>
  );
};

export default NewRecipeFormProducts;
