import { Business } from '../model/Business.entity';
import { BusinessExpense, CreateExpenseDto } from '../model/BusinessExpense.entity';
import { BusinessFoodApp } from '../model/BusinessFoodApp.entity';
import { BusinessPaymentMachine } from '../model/BusinessPaymentMachine.entity';
import { BusinessProduct } from '../model/BusinessProduct.entity';
import { Expense } from '../model/Expense.entity';
import { Recipe } from '../model/Recipe.entity';
import { businesses } from './precin_api';

export const BusinessService = {
  async getBusinessExpenses(businessId: string): Promise<BusinessExpense[]> {
    try {
      const { data } = await businesses.get<BusinessExpense[]>(`/${businessId}/expenses`);
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async getBusinessProducts(businessId: string): Promise<BusinessProduct[]> {
    try {
      const { data } = await businesses.get<BusinessProduct[]>(`/${businessId}/products`);
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async getRecipes(businessId: string): Promise<Recipe[]> {
    try {
      const { data } = await businesses.get<Recipe[]>(`/${businessId}/recipes`);
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async getRecipeById(businessId: string, recipeId: string): Promise<Recipe> {
      try {
        const { data } = await businesses.get<Recipe>(`/${businessId}/recipes/${recipeId}`);
        return data;
      } catch (err) {
        throw new Error(err.response.data.message);
    }
  },

  async getBusinessPaymentMachines(businessId: string): Promise<BusinessPaymentMachine[]> {
    try {
      const { data } = await businesses.get<BusinessPaymentMachine[]>(`/${businessId}/paymentMachines`);
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async getBusinessFoodApps(businessId: string): Promise<BusinessFoodApp[]> {
    try {
      const { data } = await businesses.get<BusinessFoodApp[]>(`/${businessId}/foodApps`);
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async createOrUpdateBusinessProduct(businessProduct: BusinessProduct) {
    /**
     * TODO: This extra conditional was needed because when this method is called from a edit dialog (update), it wasn't being recognized as a BusinessProduct object
     * which caused the call of the parseNumbers function to fail.
     * Maybe it can be improved, but for now, this will do.
     */
    try {
      if (businessProduct.id) { 
        businessProduct = BusinessProduct.from(businessProduct);
      }
      delete businessProduct.product.estimatedPrice;
      const { data } = await businesses.post<BusinessProduct>(`/${businessProduct.business.id}/products`, businessProduct);
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async deleteBusinessExpense(businessId: string, businessExpenseId: string) {
    try {
      const { status } = await businesses.delete(`/${businessId}/expenses/${businessExpenseId}`);
      return status;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async deleteBusinessPaymentMachine(businessId: string, bPaymentMachineId: string) {
    try {
      const { status } = await businesses.delete(`/${businessId}/paymentMachines/${bPaymentMachineId}`);
      return status;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async deleteBusinessFoodApp(businessId: string, bFoodAppId: string) {
    try {
      const { status } = await businesses.delete(`/${businessId}/foodApps/${bFoodAppId}`);
      return status;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async deleteBusinessProduct(businessId: string, businessProductId: string) {
    try {
      const { status } = await businesses.delete(`/${businessId}/products/${businessProductId}`);
      return status;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },
  
  async getExpenses() {
    try {
      const { data } = await businesses.get<Expense[]>(`/expenses`);
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async createOrUpdateExpense(businessExpense: BusinessExpense) {
    if(businessExpense.id) {
      businessExpense = BusinessExpense.from(businessExpense);
    }
    try {
      const { data } = await businesses.post<Business>(`/${businessExpense.businessId}/expenses`, CreateExpenseDto.fromBusinessExpense(businessExpense));
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async createOrUpdateBusinessPaymentMachine(bPaymentMachine: BusinessPaymentMachine) {
    if(bPaymentMachine.id){
      bPaymentMachine = BusinessPaymentMachine.from(bPaymentMachine);
    }
    try {
      const { data } = await businesses.post<BusinessPaymentMachine>(`/${bPaymentMachine.business.id}/paymentMachines`, bPaymentMachine);
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },

  async createOrUpdateBusinessFoodApp(bFoodApp: BusinessFoodApp) {
    if(bFoodApp.id){
      bFoodApp = BusinessFoodApp.from(bFoodApp);
    }
    try {
      const { data } = await businesses.post<BusinessFoodApp>(`/${bFoodApp.business.id}/foodApps`, bFoodApp);
      return data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  },
};
