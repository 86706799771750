import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { useFormik } from 'formik';
import React, { memo, useEffect, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import ErrorMessage from '../../errors/ErrorMessage';
import useAlert from '../../hooks/useAlert';
import useAppLoading from '../../hooks/useAppLoading';
import useYup from '../../hooks/useYup';
import { BusinessExpense, useCreateExpenseDtoValidationSchema } from '../../model/BusinessExpense.entity';
import { CustomDialogProps } from '../../model/CustomDialogProps';
import { Expense } from '../../model/Expense.entity';
import { BusinessService } from '../../services/business.service';
import { AsyncAutocomplete } from '../core/Autocomplete/AsyncAutocomplete';
import { DivisorTooltip } from '../core/Tooltip/DivisorTooltip';

const NewExpenseDialog: React.FC<CustomDialogProps<BusinessExpense>> = ({ data, open, handleClose }) => {
  const { values, handleSubmit, handleChange, setFieldValue, touched, errors } = useFormik({
    initialValues: data ?? new BusinessExpense(),
    onSubmit,
    validationSchema: useCreateExpenseDtoValidationSchema(),
  });
  const { state } = useAppContext();
  const { user } = state;
  const { setAppLoading } = useAppLoading();
  const { successAlert, errorAlert } = useAlert();
  const {validateName} = useYup();
  const [options, setOptions] = useState<Expense[]>([]);
  const [inputValue, setInputValue] = useState('');
  const loading = options.length === 0;

  useEffect(() => {
    let active = true;
    if (!loading || options.length > 0) {
      return;
    }

    (async () => {
      try {
        const expenses = await BusinessService.getExpenses();
        if (active) {
          setOptions(expenses);
        }
      } catch (err) {
        errorAlert(err.message ?? err);
      }
    })();

    return () => {
      active = false;
    };
  });

  async function onSubmit(values: BusinessExpense) {
    try {
      if (user.businesses && !values.businessId) {
        values.businessId = user.businesses[0].id;
      }
      setAppLoading(true);

      if(inputValue) {
        values.expense.name = inputValue;
      }
      await validateName(values.expense.name, 'expenseName');

      await BusinessService.createOrUpdateExpense(values);

      successAlert();
    } catch (err) {
      errorAlert(err.message ?? err);
    } finally {
      setAppLoading(false);
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Novo Custo</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>Crie um novo custo da sua empresa</DialogContentText>
          <AsyncAutocomplete
            freeSolo={true}
            options={options}
            loading={loading}
            setOptions={setOptions}
            value={values.expense}
            label={'Despesa'}
            optionAttribute={'name'}
            id='expense.name'
            onChange={(_e, value: Expense) => setFieldValue('expense.name', value.name ?? '')}
            onInputChange={(_e, value) => {setInputValue(value)}}
          />          

          <TextField margin='dense' onChange={handleChange} id='cost' label='Valor Médio' type='number' name='cost' fullWidth value={values.cost} />
          {touched.cost && errors.cost && <ErrorMessage errorMessage={errors.cost} />}

          <FormControl fullWidth>
            <InputLabel htmlFor='dividedBy'>
              <Box display='flex' alignItems='center'>
                <Typography component='span' style={{ marginRight: '5%' }}>
                  Divisor
                </Typography>
                <Tooltip placement='right' title={<DivisorTooltip />}>
                  <HelpOutline />
                </Tooltip>
              </Box>
            </InputLabel>
            <Select margin='dense' id='dividedBy' name='dividedBy' fullWidth value={values.dividedBy} onChange={handleChange}>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
          </FormControl>
          {touched.dividedBy && errors.dividedBy && <ErrorMessage errorMessage={errors.dividedBy} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancelar
          </Button>
          <Button type='submit' variant='contained' color='primary'>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(NewExpenseDialog);