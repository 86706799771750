import * as Yup from 'yup';
import { useAppContext } from '../context/AppContext';
import { BusinessProductCategory } from '../model/BusinessProduct.entity';
import { Unit } from '../model/Product.entity';
import { Locale } from '../utils/locale';
import useLabel, { LabelKey } from './useLabel';

const useYup = () => {
  const { state } = useAppContext();
  const { locale } = state;
  const { getLabel } = useLabel();

  const { requiredMessage, minMessage, maxMessage, lengthMessage, lessThanMessage, onlyNumbersMessage, equalMessage, moreThanMessage, oneOfMessage } = {
    [Locale.PT_BR]: {
      requiredMessage: 'Este campo é obrigatório',
      integerMessage: `Deve ser um inteiro`,
      onlyNumbersMessage: 'Informar somente números',
      moreThanMessage: (num: number) => `Deve ser maior que ${num}`,
      lessThanMessage: (num: number) => `Deve ser menor que ${num}`,
      oneOfMessage: (values: number[] | string[]) => `Deve ser um destes valores ${values}`,
      minMessage: (num: number) => `Deve ter no minímo ${num} caracteres`,
      maxMessage: (num: number) => `Deve ter no máximo ${num} caracteres`,
      lengthMessage: (num: number) => `Deve ter ${num} caracteres`,
      equalMessage: (name: string) => `${name} deve(m) ser igual(is)`,
    },
    [Locale.EN_US]: {
      requiredMessage: 'This field is required',
      integerMessage: `Must be an integer`,
      onlyNumbersMessage: 'Inform just numbers',
      moreThanMessage: (num: number) => `Must be more than ${num}`,
      lessThanMessage: (num: number) => `Must be less than ${num}`,
      oneOfMessage: (values: number[] | string[]) => `Must be one of these values ${values}`,
      minMessage: (num: number) => `Must have at least ${num} characters`,
      maxMessage: (num: number) => `Must have at most ${num} characters`,
      lengthMessage: (num: number) => `Must have ${num} characters`,
      equalMessage: (name: string) => `${name} must be the same`,
    },
  }[locale];

  const name = Yup.string().min(2, minMessage(2)).max(150, maxMessage(150)).required(requiredMessage);
  const email = Yup.string().email().required(requiredMessage);
  const password = Yup.string().min(6, minMessage(6)).required(requiredMessage);
  const confirmPassword = password.oneOf([Yup.ref('password'), null], equalMessage(getLabel('confirmPassword')));
  const cpf = Yup.string().matches(/^\d+$/, { message: onlyNumbersMessage }).length(11, lengthMessage(11)).required(requiredMessage);
  const cnpj = Yup.string().matches(/^\d+$/, { message: onlyNumbersMessage }).length(14, lengthMessage(14)).required(requiredMessage);
  const confirmEmail = email.oneOf([Yup.ref('email'), null], equalMessage('E-mails'));
  const financialValue = Yup.number().moreThan(0.1, moreThanMessage(0.1)).lessThan(10_000_000, lessThanMessage(10_000_000)).required(requiredMessage);
  const percentualValue = Yup.number().moreThan(0.01, moreThanMessage(0.01)).lessThan(100, lessThanMessage(100)).required(requiredMessage);
  const dividedBy = Yup.number()
    .oneOf([1, 2, 3], oneOfMessage([1, 2, 3]))
    .required(requiredMessage);
  const unit = Yup.string().oneOf(Object.values(Unit), oneOfMessage(Object.values(Unit))).required(requiredMessage);
  const category = Yup.string().oneOf(Object.values(BusinessProductCategory), oneOfMessage(Object.values(BusinessProductCategory))).required(requiredMessage);
  const businessDays = Yup.number().moreThan(0, moreThanMessage(0)).lessThan(32, lessThanMessage(32)).required(requiredMessage);
  const businessHours = Yup.number().moreThan(0, moreThanMessage(0)).lessThan(25, lessThanMessage(25)).required(requiredMessage);

  const validateName = async (value: string, label: LabelKey):Promise<void> => {
    try {
      await name.validate(value);
    } catch(err) {
        throw new Error(`${getLabel(label)}: ${err.errors.join('\n')}`);
    }
  }

  return {
    email,
    password,
    cpf,
    cnpj,
    confirmEmail,
    confirmPassword,
    name,
    financialValue,
    dividedBy,
    businessDays,
    businessHours,
    unit,
    category,
    percentualValue,
    validateName
  };
};

export default useYup;