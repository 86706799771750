import { useState } from 'react';

const useTransferList = <T>(leftColumn: Array<T>, rightColumn: Array<T>) => {
  const [left, setLeft] = useState(leftColumn);
  const [right, setRight] = useState(rightColumn);
  const [checked, setChecked] = useState<Array<T>>([]);

  function intersection(a: T[], b: T[]) {
    return a.filter(value => b.indexOf(value) !== -1);
  }

  function not(a: T[], b: T[]) {
    return a.filter(value => b.indexOf(value) === -1);
  }

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleToggle = (value: T) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return {
    left,
    right,
    checked,
    leftChecked,
    rightChecked,
    handleAllRight,
    handleAllLeft,
    handleCheckedRight,
    handleCheckedLeft,
    handleToggle,
    setLeft,
    setRight,
  };
};

export default useTransferList;