import { Box, Grid } from '@material-ui/core';
import React, { ReactNode } from 'react';
import './InputContainer.css';

const InputContainer = ({ icon, children }: { children: ReactNode; icon?: ReactNode }) => {
  return (
    <Grid className='multiple_input_container' container direction='row'>
      {icon && (
        <Box display='block' height='100%' margin='auto' fontSize='3rem' width='100%' flex='0.3' paddingX='2%' textAlign='center' className='icon_section'>
          {icon}
        </Box>
      )}
      <section className='input_section'>{children}</section>
    </Grid>
  );
};

export default InputContainer;
