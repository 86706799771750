import { Color } from '@material-ui/lab';

export class Alert {
  open: boolean = false;
  message: string = 'Atualizado com sucesso';
  severity: Color = 'success';

  static from({ open, message, severity }: { open: boolean; message?: string; severity?: Color }) {
    const alert = new Alert();
    alert.open = open;
    alert.message = message ?? alert.message;
    alert.severity = severity ?? alert.severity;

    return alert;
  }
}
